import { uuid } from 'vue-uuid';

export const randDarkColor = () => {
  const lum = -0.25;
  let hex = String(
    '#' +
      Math.random()
        .toString(16)
        .slice(2, 8)
        .toUpperCase()
  ).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  let rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
};

const getRectangleConfig = (coordinates, width = 640, height = 480) => {
  let defaultCords = {
    id: -1,
    x: 305 / width,
    y: 215 / height,
    width: 50 / width,
    height: 50 / height,
  };
  if (coordinates) defaultCords = { ...coordinates };
  return {
    rotation: 0,
    stroke: randDarkColor(),
    strokeScaleEnabled: false,
    startPointX: 0,
    startPointY: 0,
    strokeWidth: 2,
    freeDrawing: false,
    ...defaultCords,
  };
};

const getCircleConfig = (x, y, color = 'black', pointName) => {
  return {
    x: x,
    y: y,
    radius: 5,
    fill: color,
    name: pointName,
    stroke: 'white',
    strokeWidth: 1.5,
  };
};
const assignObjectPropsToConfig = (object, config) => {
  config.id = object.id;
  config.is_static = object.is_static;
  config.cordinates_of_static_object = object.cordinates_of_static_object;
  config.actual_name = object.name;
  if (object.actual_id) {
    config.actual_id = object.actual_id;
  }
};

const assignRectangleUniqueName = (config) => {
  config.name = uuid.v4();
};

export const createNewRectangleConfig = (object, width, height) => {
  const rectangleConfig = getRectangleConfig(
    object.cordinates_of_static_object,
    width,
    height
  );
  assignObjectPropsToConfig(object, rectangleConfig);
  scaleRectangleDimension(rectangleConfig, width, height);
  assignRectangleUniqueName(rectangleConfig);
  return rectangleConfig;
};

export const createNewCircleConfig = (
  points,
  width,
  height,
  color,
  pointName,
  flag = false
) => {
  var x = points[0];
  var y = points[1];
  if (!flag) {
    x = (x * width) / 640;
    y = (y * height) / 480;
  }
  const config = getCircleConfig(x, y, color, pointName);
  return config;
};

export const parseStringCoordsToObj = (stringCoords) => {
  let updated = null;
  if (stringCoords) {
    if (stringCoords[stringCoords.length - 1] !== '}') stringCoords += '}';
    updated = {
      ...JSON.parse(stringCoords),
    };
  }
  return updated;
};

export const createNewTextConfig = (
  id,
  text,
  x,
  y,
  textColor = 'black'
) => {
  if (y <= 5) {
    y += 15;
    x -= 15;
  }

  return {
    id: id + '-text',
    text: text,
    fontSize: 11,
    fontStyle: 'Bold',
    color: textColor,
    x: x,
    y: y,
    fill: textColor,
  };
};

export const scaleRectangleDimension = (object, normX, normY) => {
  object.x = object.x * normX;
  object.y = object.y * normY;
  object.width = object.width * normX;
  object.height = object.height * normY;
  if (object.midx) {
    object.midx = object.midx * normX;
  }
  if (object.midy) {
    object.midy = object.midy * normY;
  }
};

export const normalizeDimension = (object, normX, normY) => {
  object.x = object.x / normX;
  object.y = object.y / normY;
  object.width = object.width / normX;
  object.height = object.height / normY;
  if (object.midx) {
    object.midx = object.midx / normX;
  }
  if (object.midy) {
    object.midy = object.midy / normY;
  }
  roundObjectValuesToDecimal(object, 3);
};

const roundObjectValuesToDecimal = (object, round) => {
  const keys = Object.keys(object);
  keys.forEach((key) => {
    if (typeof object[key] === 'number')
      object[key] = parseFloat(object[key].toFixed(round));
  });
};

export const calculateRectangleMidPoint = (x, y, w, h) => {
  return {
    midx: (x + x + w) / 2,
    midy: (y + y + h) / 2,
  };
};

export const objectMap = (object, fn) => {
  return Object.fromEntries(
    Object.entries(object).map(([k, v], i) => [k, fn(v, k, i)])
  );
};

export const normalizePolygonPoints = (points, width = 640, height = 480) => {
  const normX = width;
  const normY = height;
  return points.reduce((res, el, index) => {
    const normPoint = index % 2 === 0 ? el / normX : el / normY;
    return (res = [...res, normPoint]);
  }, []);
};
