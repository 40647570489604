<template>
  <template>
    <ShotcutKeysModal
      :open-modal="shortcutModal"
      @closeModal="displayShortcutModal"
  /></template>
  <a-row
    class="h-100 d-flex align-items-center px-0 py-0 mx-0"
    :gutter="[24, 0]"
  >
    <a-col
      span="18"
      class="h-100 d-flex flex-column px-0 py-0"
      style="position: relative"
    >
      <div
        ref="floatingCard"
        style="width: 0; height: 0; background-color: white; position: absolute"
      >
        <div v-if="activeObjectArea" class="p-2">
          <div class="fw-semibold">Label Object</div>
          <form @submit.prevent="handleSave" action="">
            <div>
              <a-select
                class="my-3"
                ref="annotateSelect"
                v-model:value="annotate"
                show-search
                style="width: 100%"
                :size="'small'"
                :options="annotationObjectList"
                :filterOption="filterOption"
                :default-active-first-option="false"
                :allowClear="true"
                @search="handleSearchChange"
                :notFoundContent="null"
              ></a-select>
            </div>
            <div class="d-flex justify-content-end align-items-center my-1">
              <a-button
                :loading="creatingTask"
                @click="handleSave"
                type="primary"
                size="small"
                >Save</a-button
              >
            </div>
          </form>
        </div>
      </div>

      <a-card
        :body-style="{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }"
        size="small"
        id="annotation-object"
        class="annotation-image-card d-flex flex-column flex-grow-1"
        style="position: relative"
      >
        <a-spin
          v-if="imageLoading"
          size="large"
          tip="Loading Image..."
          class="m-auto"
        />

        <a-result
          v-else-if="!imageLoading && !image"
          id="not-found-label-image"
          title="Image Not Found!"
          class="m-auto"
        >
          <template #icon>
            <exclamation-circle-outlined class="text-danger" />
          </template>
        </a-result>
        <template #title>
          <div class="card-title">
            <a-space direction="vertical" :size="30" style="text-align: center">
              <a-tooltip>
                <template #title>Draw Shape (W or w)</template>
                <a-button
                  id="region-modal-btn"
                  shape="circle"
                  type="primary"
                  :size="'large'"
                  :disabled="imageLoading || isObjectFetching"
                  :style="{
                    backgroundColor: isAddingRectangle ? 'blue' : 'white',
                    color: isAddingRectangle ? 'white' : 'blue',
                  }"
                  @click="setNewObjectConfigToDraw"
                >
                  <template #icon>
                    <border-outlined />
                  </template>
                </a-button>
              </a-tooltip>
              <a-tooltip placement="topLeft">
                <template #title>Apply Previous Labels</template>
                <a-button
                  id="region-modal-btn"
                  shape="circle"
                  type="primary"
                  :size="'large'"
                  :disabled="
                    imageLoading ||
                    prevImageButtonDisabled ||
                    isApplyPreviousLabel
                  "
                  :style="
                    imageLoading ||
                    prevImageButtonDisabled ||
                    isApplyPreviousLabel
                      ? {}
                      : {
                          backgroundColor: isHistoryApplied ? 'blue' : 'white',
                          color: isHistoryApplied ? 'white' : 'blue',
                        }
                  "
                  @click="renderLastExistingObjectList"
                >
                  <template #icon>
                    <HistoryOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip placement="topLeft">
                <template #title>Zoom In (Ctrl - Alt - +)</template>
                <a-button
                  id="zoom-in-btn"
                  shape="circle"
                  type="primary"
                  :size="'large'"
                  :disabled="imageLoading"
                  :style="{
                    backgroundColor: 'white',
                    color: 'blue',
                  }"
                  @click="handleLayerZoomIn"
                >
                  <template #icon>
                    <ZoomInOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip placement="topLeft">
                <template #title>Zoom Out (Ctrl - Alt - -)</template>
                <a-button
                  id="zoom-in-btn"
                  shape="circle"
                  type="primary"
                  :size="'large'"
                  :style="
                    layerScale <= 1
                      ? {}
                      : {
                          backgroundColor: 'white',
                          color: 'blue',
                        }
                  "
                  :disabled="layerScale <= 1"
                  @click="handleLayerZoomOut"
                >
                  <template #icon>
                    <ZoomOutOutlined />
                  </template>
                </a-button>
              </a-tooltip>
              <a-popover
                placement="right"
                trigger="click"
                v-model:visible="isPopoverVisible"
              >
                <template #content>
                  <!-- Container for buttons with vertical layout -->
                  <div style="display: flex; flex-direction: column; gap: 8px">
                    <a-tooltip placement="topLeft">
                      <template #title>Delete (delete)</template>
                      <a-button
                        id="zoom-in-btn"
                        shape="circle"
                        type="primary"
                        :size="'large'"
                        :style="{
                          backgroundColor: 'white',
                          color: 'blue',
                        }"
                        @click="deleteObject"
                      >
                        <template #icon>
                          <DeleteOutlined />
                        </template>
                      </a-button>
                    </a-tooltip>

                    <a-tooltip placement="topLeft">
                      <template #title>Copy (Ctrl - C)</template>
                      <a-button
                        id="zoom-in-btn"
                        shape="circle"
                        type="primary"
                        :size="'large'"
                        :style="{
                          backgroundColor: 'white',
                          color: 'blue',
                        }"
                        @click="copySelectedShapes"
                      >
                        <template #icon>
                          <CopyOutlined />
                        </template>
                      </a-button>
                    </a-tooltip>
                  </div>
                </template>

                <a-tooltip placement="topLeft">
                  <template #title>Select All (Ctrl - Q)</template>
                  <a-button
                    id="zoom-in-btn"
                    shape="circle"
                    type="primary"
                    :size="'large'"
                    :style="
                      Object.keys(groups.rectangles).length === 0
                        ? {}
                        : {
                            backgroundColor: 'white',
                            color: 'blue',
                          }
                    "
                    @click="handleSelectAll"
                    :disabled="Object.keys(groups.rectangles).length === 0"
                  >
                    <template #icon>
                      <SelectOutlined />
                    </template>
                  </a-button>
                </a-tooltip>
              </a-popover>

              <a-tooltip placement="topLeft" v-if="copiedShapes != null">
                <template #title>Paste (Ctrl - V)</template>
                <a-button
                  id="zoom-in-btn"
                  shape="circle"
                  type="primary"
                  :size="'large'"
                  :style="{
                    backgroundColor: 'white',
                    color: 'blue',
                  }"
                  @click="pasteShapes"
                >
                  <template #icon>
                    <SnippetsOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip placement="topLeft">
                <template #title>Fit Window (Ctrl - F or f)</template>
                <a-button
                  id="zoom-in-btn"
                  shape="circle"
                  type="primary"
                  :size="'large'"
                  :style="
                    layerScale <= 1
                      ? {}
                      : {
                          backgroundColor: 'white',
                          color: 'blue',
                        }
                  "
                  :disabled="layerScale <= 1"
                  @click="handleLayerFitWindow"
                >
                  <template #icon>
                    <FullscreenOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Info</template>
                <a-button
                  id="mark-info-btn"
                  type="primary"
                  shape="circle"
                  :size="'large'"
                  :style="{
                    backgroundColor: 'white',
                    color: 'blue',
                  }"
                  @click="handleShortcutInfo"
                >
                  <template #icon>
                    <InfoCircleOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Save (Ctrl - S)</template>
                <a-button
                  id="mark-object-save-btn"
                  type="primary"
                  shape="circle"
                  :size="'large'"
                  :disabled="!isAnythingUpdated || imageLoading"
                  @click="saveCurrentImageAnnotation"
                >
                  <template #icon>
                    <SaveOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Previous Image (A or a)</template>
                <a-button
                  id="mark-object-previous-btn"
                  type="primary"
                  shape="circle"
                  :size="'large'"
                  :disabled="prevImageButtonDisabled || imageLoading"
                  @click="changeImage(-1)"
                >
                  <template #icon>
                    <ArrowLeftOutlined />
                  </template>
                </a-button>
              </a-tooltip>

              <a-tooltip>
                <template #title>Next Image (D or d)</template>
                <a-button
                  id="mark-object-next-btn"
                  type="primary"
                  shape="circle"
                  :size="'large'"
                  :disabled="nextImageButtonDisabled || imageLoading"
                  @click="changeImage(1)"
                >
                  <template #icon>
                    <ArrowRightOutlined />
                  </template>
                </a-button>
              </a-tooltip>
            </a-space>
          </div>
        </template>

        <template v-if="!imageLoading" #cover>
          <div
            id="deleteContextMenu"
            :style="{
              display: deleteShow ? 'initial' : 'none !important',
            }"
          >
            <button id="delete-button" @click="deleteObject">Delete</button>
          </div>
          <v-stage
            ref="stage"
            key="stage"
            id="mo-define-stage"
            :config="stageSize"
            :style="layerStyle"
            @click="handleStageClick"
            @mousedown="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
            @contextmenu="handleDeleteMenuShow"
            style="position: relative"
          >
            <v-layer
              ref="layer"
              :scaleX="layerScale"
              :scaleY="layerScale"
              :draggable="layerScale <= 1 || isDrawing ? false : true"
              @mousedown="handleLayerMouseDown"
              @mouseup="handleLayerMouseUp"
              @wheel="handleWheel"
              @mousemove="handleLayerMouseMove"
              :dragBoundFunc="layerDragBoundFunc"
            >
              <v-image ref="image" :config="imageConfig" />
              <v-group
                v-for="(rect, index) in groups.rectangles"
                :key="rect.name + 1"
                :config="{ draggable: !isDrawing, id: `${rect.name + 1}` }"
                @dragstart="handleGroupDragStart"
                @dragmove="onGroupDragMove"
                @dragend="handleGroupDragEnd"
              >
                <v-rect
                  :key="rect.name"
                  :config="{
                    ...rect,
                    x:
                      rect.x !== -1
                        ? rect.x
                        : Math.min(
                            rect.startPointX,
                            rect.startPointX + rect.width
                          ),
                    y:
                      rect.y !== -1
                        ? rect.y
                        : Math.min(
                            rect.startPointY,
                            rect.startPointY + rect.height
                          ),
                    width: Math.abs(rect.width),
                    height: Math.abs(rect.height),
                  }"
                  :ref="rect.name + '_ref'"
                  @click="(event) => handleTransformDisplay(event, rect, index)"
                  @dblclick="(event) => handleOpenFloatingBoat(event, rect)"
                  @transformend="handleTransformEnd"
                />
                <v-text
                  :key="index"
                  :name="groups.labels[index].text"
                  :config="groups.labels[index]"
                />
              </v-group>
              <v-transformer
                v-if="!newRect"
                ref="transformer"
                :config="transformerConfig"
              />
            </v-layer>
          </v-stage>
        </template>

        <template #extra> </template>
      </a-card>
    </a-col>

    <a-col span="6" class="h-100 d-flex flex-column px-0 py-0">
      <ObjectSettings
        :imageLoading="imageLoading"
        :textColor="textColor"
        :objectList="annotateObjects"
        :modelChoice="modelChoice"
        @updateChecked="updateChecked"
        @changeLabelColor="changeLabelColor"
        @changeLabelSize="changeLabelSize"
        @updateCurrentObject="updateCurrentObjectName"
      />
      <a-list
        id="mark-objects-annotate-obj-list"
        bordered
        class="objects-list"
        size="small"
        :loading="{ spinning: isObjectFetching, size: 'large' }"
        :data-source="labeledObjectList"
        item-layout="horizontal"
      >
        <template #header>
          <span>Objects</span>
        </template>
        <template #renderItem="{ item, index }">
          <a-list-item
            :id="'mo-annotate-obj-' + index"
            class="pl-2 clickable"
            :class="{
              'selected-object': selectedShapeName === item.name,
            }"
          >
            <a-list-item-meta @click.prevent="onObjectSelect(item)">
              <template #title>
                <span>
                  {{ item.actual_name }}
                </span>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
      <file-list
        :imageList="imageList"
        :index="imageIndexList"
        @loadImage="loadImageRange"
        @remove-events="removeEvents"
        @subscribe-events="subscribeEvents"
      />
    </a-col>
  </a-row>
</template>

<script>
import { getRandomColor } from 'src/config/color-config';
import { parseStringCoordsToObj } from '../../LabelData/ObjectAnnotation/helpers';
import { colorsConfig } from '@/utils/detector';
import {
  LoadingOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  EditOutlined,
  FontSizeOutlined,
  BorderOutlined,
  HistoryOutlined,
  HighlightOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  SelectOutlined,
  DeleteOutlined,
  CopyOutlined,
  SnippetsOutlined,
  FullscreenOutlined,
  SaveOutlined,
  ThunderboltFilled,
  InfoCircleOutlined,
} from '@ant-design/icons-vue';
import axios from 'axios';
import * as OBJECT from '../../LabelData/ObjectAnnotation/object';
import httpClient from '../../../../../service/httpClient';
import objectsMarkingMixin from 'src/mixins/objectsMarking';
import DetectorService from 'src/services/detector.js';
import { mapActions, mapGetters } from 'vuex';
import { h } from 'vue';
import { uuid } from 'vue-uuid';
import { deepClone } from '@/utils/task';
import { isHandAndFaceObj } from '@/utils/detector';
import FileList from './FileList.vue';
import ObjectSettings from './ObjectSettings.vue';
import ShotcutKeysModal from './ShortcutKeysModal.vue';
import { shortcutKeys } from './config';
import {
  createNewRectangleConfig,
  createNewTextConfig,
  scaleRectangleDimension,
} from '../../LabelData/ObjectAnnotation/helpers';

export default {
  name: 'ObjectsLabellingTool',
  components: {
    DownOutlined,
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FontSizeOutlined,
    BorderOutlined,
    HistoryOutlined,
    HighlightOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    SelectOutlined,
    CopyOutlined,
    SnippetsOutlined,
    FullscreenOutlined,
    SaveOutlined,
    InfoCircleOutlined,
    FileList,
    ObjectSettings,
    ShotcutKeysModal,
  },

  mixins: [objectsMarkingMixin],
  inject: ['toast'],

  props: [
    'imagePathInBucket',
    'imageList',
    'taskId',
    'annotationFileName',
    'annotationFilePath',
    'imageId',
    'imageObj',
    'imageIndex',
    'totalResults',
    'currentPage',
    'pageSize',
    'startIndex',
    'endIndex',
    'presignedUrlList',
  ],

  emits: [
    'changeImageAnnotationPath',
    'nextImage',
    'prevImage',
    'closeModal',
    'updateAnnotationPath',
  ],

  setup() {
    const indicator = h(LoadingOutlined, {
      style: {
        fontSize: '36px',
        margin: 'auto',
      },
      spin: true,
    });
    return {
      indicator,
      isHandAndFaceObj,
    };
  },

  data() {
    return {
      isNewLabel: false,
      newRect: null,
      isNewRectAdded: false,
      isAnnotateObjectChecked: true,
      creatingTask: false, // loader
      // selectedShapeId: null, // so that we can update the object of selected shape
      selectedShape: null,
      selectedShapes: [],
      showSaveButton: false,
      newLabel: '',
      annotate: null,
      debounceTimeout: null,
      generatingImageEmbedding: null,
      labeledObjectList: [],
      disableDelete: false,
      selectedShapeName: '',
      selectedShapeId: -1,
      imageToBeAnnotated: '',
      loadingSemtrack: false,
      image: null,
      imageLoading: true,
      groups: {
        rectangles: {},
        labels: {},
      },
      imageDimensions: {
        width: 0,
        height: 0,
      },
      stageSize: {
        width: 640,
        height: 480,
      },
      transformerConfig: {
        keepRatio: false,
        ignoreStroke: true,
        rotateEnabled: false,
        flipEnabled: false,
        enabledAnchors: [
          'top-left',
          'top-right',
          'bottom-left',
          'bottom-right',
          'middle-left',
          'middle-right',
          'top-center',
          'bottom-center',
        ],
      },
      currentObjectName: null,
      currentObjectId: -1,
      isAddingRectangle: false,
      isHistoryApplied: false,
      textColor: 'white',
      currentLabelSize: 15,
      labelVerticalGap: 17,
      img_idx: -1,
      isObjectFetching: false,
      isAnythingUpdated: false,
      debouncedChangeImage: null,
      temp_groups: {
        rectangles: {},
        labels: {},
      },
      deleteShow: false,
      layerScale: 1,
      scaleBy: 1.05,
      isWheelScroll: false,
      isLayerDragging: false,
      annotationCache: {},
      imagePresignedUrl: {},
      currentRectName: null,
      isDrawing: false,
      imageList: this.imageList,
      imageIndexList: this.imageIndex,
      isCachingImage: false,
      isCachingAnnotation: false,
      activeObjectArea: false,
      objectName: '',
      shortcutModal: false,
      keyHandlers: shortcutKeys,
      initialGroupPosition: { x: 0, y: 0 },
      initialRectPositions: [],
      isAllNodeSelected: false,
      uploadQueue: Promise.resolve(),
      annotateObjectStroke: null,
      cardAntd: 'ant-card-cover',
      searchValue: null,
      copiedShapes: null,
      isApplyPreviousLabel: false,
    };
  },

  computed: {
    ...mapGetters([
      'organization',
      'taskObjectList',
      'taskObjects',
      'selectedTask',
      'prevGroups',
      'prevPolygons',
      'modelChoice',
      'imagesForAnnotation',
      'prevPageLastImageObj',
    ]),
    isPopoverVisible() {
      return this.selectedShapeName || this.isAllNodeSelected;
    },
    annotationObjectList() {
      if (!this.annotateObjects || this.annotateObjects.length === 0) {
        return [];
      }
      return this.annotateObjects
        .filter((o) => this.filterAnnotationList(o))
        .map((o) => ({
          value: o.id,
          label: o.name,
        }));
    },

    layerStyle() {
      return {
        cursor:
          this.isAddingRectangle || this.isDrawing
            ? 'crosshair'
            : this.isLayerDragging
            ? 'grab'
            : 'default',
      };
    },

    nextImageButtonDisabled() {
      return this.imageIndex + 1 >= this.totalResults;
    },

    prevImageButtonDisabled() {
      return this.imageIndex - 1 < 0;
    },

    annotateObjects() {
      return this.taskObjects.filter((obj) => !obj.is_static).sort();
    },

    nonStaticObjects() {
      return this.taskObjects
        .filter((obj) => obj && !obj.is_static)
        .sort()
        .map((o, index) => {
          return { ...o };
        });
    },

    imageConfig() {
      return {
        image: this.image,
        name: 'currentImage',
        ...this.stageSize,
      };
    },

    objectStrokeColor() {
      let stroke = {};
      if (this.annotateObjects.length === 0) return stroke;
      this.annotateObjects.forEach((item) => {
        if (isHandAndFaceObj(item.name, this.modelChoice)) return;
        stroke[item.name] = item.outline_color;
      });
      return stroke;
    },
  },

  unmounted() {
    // cleanup
    this.resetInitState();
    this.removeEvents();
  },

  watch: {
    layerScale(value) {
      if (!this.isWheelScroll) this.updateLayerZoom(value);
      if (value === 1) {
        const layer = this.$refs.layer.getNode();
        layer.x(0);
        layer.y(0);
        this.isLayerDragging = false;
      }
    },

    async imageObj(value) {
      if (!value) return;
      // this.imageLoading = true;
      const res = await this.loadImage(value);
      if (!res) {
        this.imageLoading = false;
        return;
      }
      await this.renderExistingObjectList();
      this.imageLoading = false;
    },

    textColor(newColor) {
      const { labels } = this.groups;
      const newLabels = Object.entries(labels).reduce((res, [key, value]) => {
        res[key] = { ...value, color: newColor, fill: newColor };
        return res;
      }, {});
      this.groups['labels'] = newLabels;
    },
  },

  async created() {
    // this.debouncedChangeImage = this.debounce(this.changeImage, 300);
    // await this.preloadImagesUrl();
    await this.preloadImageandAnnotation();
    this.isObjectFetching = true;
    this.imageLoading = true;
    const res = await this.loadImage(this.imageObj);
    if (!res) {
      this.imageLoading = false;
      this.isObjectFetching = false;
      return;
    }
    this.imageLoading = false;
    this.img_idx = this.imageIndex;
    this.imageIndexList = this.img_idx;
    await this.updateImageConfig();
    await this.renderExistingObjectList();
    await this.updateStats();
    this.isObjectFetching = false;
    if (this.img_idx > 0) {
      if (
        this.annotationCache[this.img_idx - 1] == null ||
        this.annotationCache[this.img_idx - 1] === ''
      ) {
        this.isApplyPreviousLabel = true;
      }
    }
  },

  mounted() {
    this.subscribeEvents();
  },

  methods: {
    ...mapActions([
      'addTaskObject',
      'setTaskObjectList',
      'setPrevGroups',
      'setPrevPolygons',
      'getTaskObjects',
      'updateTaskObject',
    ]),

    handleSearchChange(value) {
      if (!value) return;
      this.searchValue = value;
      this.annotate = this.searchValue;
    },

    ResizeStageObjects(e) {
      this.$nextTick(() => {
        const container = document.getElementsByClassName(this.cardAntd);
        if (!container?.length) return;
        const cardCover = container[0];
        const { rectangles, labels } = this.groups;
        this.normalizeRectangle(rectangles);
        this.updateStageAndImageSize(cardCover);
        this.deNormalizeRectangle(rectangles, labels);
        this.updateLabelSize();
        if (this.newRect && this.activeObjectArea && !this.creatingTask) {
          this.removeRuntimeObject();
          this.isNewRectAdded = false;
          this.currentRectName = null;
        }
        this.createGroupClone();
      });
    },

    updateStageAndImageSize(cover) {
      this.stageSize.width = cover.offsetWidth;
      this.stageSize.height = cover.offsetHeight;
      this.imageDimensions.width = this.stageSize.width;
      this.imageDimensions.height = this.stageSize.height;
    },

    normalizeRectangle(rectangles) {
      for (const [name, rectangle] of Object.entries(rectangles)) {
        this.normalizeDim(
          rectangle,
          this.stageSize.width,
          this.stageSize.height
        );
      }
    },

    deNormalizeRectangle(rectangles, labels) {
      for (const [name, rectangle] of Object.entries(rectangles)) {
        scaleRectangleDimension(
          rectangle,
          this.stageSize.width,
          this.stageSize.height
        );
        const yAxis = this.getDefaultLabelCoordinate(rectangle.y);
        const xAxis = rectangle.x;
        labels[name].x = xAxis;
        labels[name].y = yAxis;
      }
    },

    async handleImageObj(value) {
      if (!value) return;
      this.imageLoading = true;
      const res = await this.loadImage(value);
      if (!res) {
        this.imageLoading = false;
        return;
      }
      await this.renderExistingObjectList();
      this.imageLoading = false;
    },

    handleOpenFloatingBoat(event, rect) {
      if (this.newRect !== null) {
        return;
      }
      if (event.evt?.button !== 0) return;
      this.handleFloatingBoatPosition(rect);
    },

    updateChecked(checked) {
      this.isAnnotateObjectChecked = checked;
    },

    async addObject(payload) {
      return await this.addTaskObject(payload);
    },

    isValidPattern(value) {
      const pattern = new RegExp(
        '^(?:[A-Za-z]+(?: [A-Za-z]+)*(?:[\\s]*[A-Za-z0-9]+)?)$'
      );
      if (pattern.test(value)) return true;
      return false;
    },

    async handleSave() {
      if (!this.annotate) return;
      if (typeof this.annotate == 'string') {
        if (isHandAndFaceObj(this.annotate?.toLowerCase(), this.modelChoice))
          return this.toast.error(
            `Creating an annotation for "${this.annotate}" is not permitted.`
          );
        if (!this.isValidPattern(this.annotate?.toLowerCase()))
          return this.toast.info(
            'Object name should only contains valid Alpha numeric words!'
          );
      }
      this.creatingTask = true;
      let currentObj = null;
      if (typeof this.annotate == 'string')
        currentObj = this.taskObjects.find(
          (obj) => obj.name?.toLowerCase() === this.annotate?.toLowerCase()
        );
      if (typeof this.annotate == 'string' && !currentObj) {
        return await this.createObject(currentObj);
      }
      const { outline_color, name } =
        currentObj ?? this.taskObjects.find((data) => data.id == this.annotate);

      this.groups.rectangles = {
        ...this.groups.rectangles,
        [this.selectedShapeId]: {
          ...this.groups.rectangles[this.selectedShapeId],
          actual_name: name,
          stroke: outline_color,
        },
      };
      this.groups.labels = {
        ...this.groups.labels,
        [this.selectedShapeId]: {
          ...this.groups.labels[this.selectedShapeId],
          text: name,
        },
      };
      this.addObjectInLabeledList(this.groups.rectangles[this.selectedShapeId]);
      this.resetRuntimeObject();
      // this.cacheonSelect();
    },

    resetRuntimeObject() {
      this.isAnythingUpdated = true;
      this.annotate = null;
      this.searchValue = null;
      this.newRect = null;
      this.creatingTask = false;
      this.activeObjectArea = false;
      this.annotateObjectStroke = null;
    },

    async createObject(currentObj) {
      const payload = {
        name: this.annotate,
        task: this.selectedTask,
        outline_color: this.groups.rectangles[this.selectedShapeId].stroke,
      };
      await this.addObject(payload);
      await this.getTaskObjects(this.selectedTask);
      currentObj = this.taskObjects.find((obj) => obj.name === this.annotate);
      this.groups.rectangles[this.selectedShapeId].actual_id = currentObj.id;
      this.groups.rectangles = {
        ...this.groups.rectangles,
        [this.selectedShapeId]: {
          ...this.groups.rectangles[this.selectedShapeId],
          actual_name: this.annotate,
          stroke: payload.outline_color,
        },
      };
      this.groups.labels = {
        ...this.groups.labels,
        [this.selectedShapeId]: {
          ...this.groups.labels[this.selectedShapeId],
          text: this.annotate,
        },
      };
      this.addObjectInLabeledList(this.groups.rectangles[this.selectedShapeId]);
      this.resetRuntimeObject();
    },

    onSearch(value) {
      this.objectName = value;
    },

    filterOption(input, option) {
      if (option.label.toLowerCase().startsWith(input.toLowerCase())) {
        return option.label.toLowerCase().startsWith(input.toLowerCase());
      } else {
        this.annotate = input;
        return;
      }
    },

    filterAnnotationList(object) {
      return !isHandAndFaceObj(object.name.toLowerCase(), this.modelChoice);
    },
    handleShortcutInfo() {
      this.displayShortcutModal(true);
    },

    displayShortcutModal(visible) {
      this.shortcutModal = visible;
    },

    removeEvents() {
      document.removeEventListener('keydown', this.handleKeyDownEvents);
      window.addEventListener('resize', this.ResizeStageObjects);
    },

    subscribeEvents() {
      document.addEventListener('keydown', this.handleKeyDownEvents);
      window.addEventListener('resize', this.ResizeStageObjects);
    },

    handlePreviousImage() {
      if (this.prevImageButtonDisabled || this.imageLoading) return;
      this.changeImage(-1);
    },

    handleNextImage() {
      if (this.nextImageButtonDisabled || this.imageLoading) return;
      this.changeImage(1);
    },

    handleTransformDisplay(event, shape, index) {
      // this.isAddingRectangle = false;

      if (this.newRect || this.isAddingRectangle) {
        return;
      }
      if (event.evt.ctrlKey) {
        const transformerNode = this.$refs.transformer.getNode();
        const stage = transformerNode.getStage();
        const layer = this.$refs.layer.getNode();
        const selectedNode = stage.findOne('.' + shape.name);
        if (selectedNode && !this.selectedShapes.includes(selectedNode)) {
          this.selectedShapes.push(selectedNode);

          // Add a new transformer to the selected node
          const newTransformer = new Konva.Transformer({
            node: selectedNode,
            name: 'transformer',
            ...this.transformerConfig,
          });
          layer.add(newTransformer);
        }
      } else {
        this.selectedShapes = [];
      }
      this.selectedShapeName = shape.name;
      this.selectedShape = shape;
      this.selectedShapeId = index;
    },

    handleTransformEnd(e) {
      this.isAnythingUpdated = true;
      this.handleTransform(e, true);
    },
    handleGroupDragStart(event) {
      if (!this.isAllNodeSelected) return;
      // Store the initial group position
      this.initialGroupPosition = { ...event.target.position() };

      // Store the initial positions of the selected rectangles
      const selectedShapeNames = Object.keys(this.groups.rectangles);
      for (const shapeName of selectedShapeNames) {
        this.initialRectPositions[shapeName] = {
          x: this.groups.rectangles[shapeName].x,
          y: this.groups.rectangles[shapeName].y,
          startx: this.groups.rectangles[shapeName].startPointX,
          starty: this.groups.rectangles[shapeName].startPointY,
          labelX: this.groups.labels[shapeName].x,
          labelY: this.groups.labels[shapeName].y,
        };
      }
    },
    onGroupDragMove(event) {
      if (!this.isAllNodeSelected) return;
      const newGroupPosition = event.target.position();
      const selectedShapeNames = Object.keys(this.groups.rectangles);
      const deltaX = newGroupPosition.x - this.initialGroupPosition.x;
      const deltaY = newGroupPosition.y - this.initialGroupPosition.y;
      selectedShapeNames.forEach((shapeName) => {
        if (this.selectedShapeName != shapeName) {
          const initialPosition = this.initialRectPositions[shapeName];
          this.groups.rectangles[shapeName].x = initialPosition.x + deltaX;
          this.groups.rectangles[shapeName].y = initialPosition.y + deltaY;
          this.groups.rectangles[shapeName].startPointX =
            initialPosition.startx + deltaX;
          this.groups.rectangles[shapeName].startPointY =
            initialPosition.starty + deltaY;
          this.groups.labels[shapeName].x = initialPosition.labelX + deltaX;
          this.groups.labels[shapeName].y = initialPosition.labelY + deltaY;
        }
      });

      // event.evt.preventDefault();
    },
    resetTransform() {
      const transformerNode = this.$refs?.transformer?.getNode();
      if (!transformerNode) return;
      transformerNode?.nodes([]);
    },
    handleGroupDragEnd(event) {
      const layer = this.$refs.layer.getNode();
      const layerWidth = layer.width();
      const layerHeight = layer.height();
      const rectIndex = 0;
      let position =
        event.target.children[rectIndex].getAbsolutePosition(layer);
      const name = event.target.children[rectIndex].name();
      const rect = event.target.children[rectIndex];
      const groupWidth = rect.width();
      const groupHeight = rect.height();

      position.x = Math.max(
        0,
        Math.min(position.x, layerWidth - groupWidth - 1)
      );
      position.y = Math.max(
        0,
        Math.min(position.y, layerHeight - groupHeight - 1)
      );
      this.updateGroupItemsCoordinates(name, position);
      this.isAnythingUpdated = true;
      event.target.position({ x: 0, y: 0 });
    },

    updateGroupItemsCoordinates(name, position) {
      const { x, y } = position;
      this.groups.rectangles[name].x = x;
      this.groups.rectangles[name].y = y;
      this.groups.labels[name].x = x;
      this.groups.labels[name].y =
        this.getLabelVerticalCoordinate(name) ??
        this.getDefaultLabelCoordinate(y);
    },

    handleKeyDownEvents(e) {
      if (this.activeObjectArea && e.key == 'Enter') {
        this.handleSave();
        return;
      }
      if (this.activeObjectArea) {
        return;
      }
      for (const [
        key,
        { ctrl, alt, handler, insensitiveKey },
      ] of Object.entries(this.keyHandlers)) {
        let userKey = e.key;
        if (insensitiveKey && e.key?.toLowerCase() === key) {
          userKey = e.key?.toLowerCase();
        }
        if (
          userKey === key &&
          (ctrl === undefined || e.ctrlKey === ctrl) &&
          (alt === undefined || e.altKey === alt)
        ) {
          e.preventDefault();
          this[handler]();
          break;
        }
      }
    },

    layerDragBoundFunc(pos) {
      return this.updateLayerBounds(pos);
    },

    handleLayerZoomIn() {
      if (this.imageLoading) return;
      this.layerScale = this.layerScale * this.scaleBy;
    },

    handleLayerZoomOut() {
      if (this.layerScale <= 1) {
        this.resetLayerScale();
        return;
      }
      this.layerScale = this.layerScale / this.scaleBy;
    },
    handleSelectAll(e) {
      if (this.isAllNodeSelected) {
        this.handleDeselectAll();
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }
      const shape_name = Object.keys(this.groups.rectangles);
      const layer = this.$refs.layer.getNode();
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const transformers = [];

      // Remove previous transformers if any
      layer
        .find('.transformer')
        .forEach((transformer) => transformer.destroy());

      for (const shape in shape_name) {
        const selectedNode = stage.findOne('.' + shape_name[shape]);
        if (selectedNode) {
          const newTransformer = new Konva.Transformer({
            node: selectedNode,
            name: 'transformer',
            ...this.transformerConfig,
          });
          layer.add(newTransformer);
          transformers.push(newTransformer);
        }
      }

      // layer.batchDraw();
      this.isAllNodeSelected = true;
    },

    handleDeselectAll() {
      const layer = this.$refs.layer.getNode();
      layer
        .find('.transformer')
        .forEach((transformer) => transformer.destroy());
      layer.batchDraw();
      this.isAllNodeSelected = false;
      this.selectedShapes = [];
    },
    copySelectedShapes() {
      const transformerNode = this.$refs.transformer.getNode();
      const stage = transformerNode.getStage();
      const selectedShapes = stage
        .find('.transformer')
        .map((transformer) => transformer.node());

      // If no transformers are found, check for single selected shape
      if (selectedShapes.length === 0 && this.selectedShapeName) {
        const selectedNode = stage.findOne('.' + this.selectedShapeName);
        if (selectedNode) {
          selectedShapes.push(selectedNode);
        }
      }
      this.copiedShapes = selectedShapes.map((shape) => {
        const shapeAttrs = { ...shape.attrs };

        return shapeAttrs;
      });
    },
    pasteShapes() {
      const newGroups = {
        rectangles: {},
        labels: {},
      };

      if (!this.copiedShapes || !this.copiedShapes.length) return;

      this.copiedShapes.forEach((copiedShape) => {
        const { width, height } = this.stageSize;

        // Create a new rectangle configuration by spreading `o` and adding additional properties
        let rectangle = {
          ...createNewRectangleConfig(copiedShape, width, height),
          id: uuid.v4(),
          width: copiedShape.width,
          height: copiedShape.height,
          x: copiedShape.x,
          y: copiedShape.y,
          actual_name: copiedShape.actual_name,
          stroke: copiedShape.stroke,
        };

        // Create a new label configuration
        const label = createNewTextConfig(
          rectangle.id,
          rectangle.actual_name,
          rectangle.x,
          rectangle.y - 15,
          this.textColor
        );

        // Assign rectangle and label to the newGroups
        newGroups.rectangles[rectangle.name] = rectangle;
        newGroups.labels[rectangle.name] = label;

        // Add the object to the labeled list
        this.addObjectInLabeledList(rectangle);
      });

      // Merge newGroups with existing groups
      this.groups.rectangles = {
        ...this.groups.rectangles,
        ...newGroups.rectangles,
      };
      this.groups.labels = { ...this.groups.labels, ...newGroups.labels };
      this.updateLabelSize();
      this.isAnythingUpdated = true;
    },
    moveSelectedNodesup() {
      if (this.isAllNodeSelected) {
        const selectedShapeNames = Object.keys(this.groups.rectangles);
        selectedShapeNames.forEach((shapeName) => {
          this.groups.rectangles[shapeName].y -= 1;
          this.groups.rectangles[shapeName].startPointY -= 1;
          this.groups.labels[shapeName].y -= 1;
        });
      } else {
        this.groups.rectangles[this.selectedShapeId].y -= 1;
        this.groups.rectangles[this.selectedShapeId].startPointY -= 1;
        this.groups.labels[this.selectedShapeId].y -= 1;
      }
      this.isAnythingUpdated = true;
    },

    moveSelectedNodesdown() {
      if (this.isAllNodeSelected) {
        const selectedShapeNames = Object.keys(this.groups.rectangles);
        selectedShapeNames.forEach((shapeName) => {
          this.groups.rectangles[shapeName].y += 1;
          this.groups.rectangles[shapeName].startPointY += 1;
          this.groups.labels[shapeName].y += 1;
        });
      } else {
        this.groups.rectangles[this.selectedShapeId].y += 1;
        this.groups.rectangles[this.selectedShapeId].startPointY += 1;
        this.groups.labels[this.selectedShapeId].y += 1;
      }
      this.isAnythingUpdated = true;
    },

    moveSelectedNodesleft() {
      if (this.isAllNodeSelected) {
        const selectedShapeNames = Object.keys(this.groups.rectangles);
        selectedShapeNames.forEach((shapeName) => {
          this.groups.rectangles[shapeName].x -= 1;
          this.groups.rectangles[shapeName].startPointX -= 1;
          this.groups.labels[shapeName].x -= 1;
        });
      } else {
        this.groups.rectangles[this.selectedShapeId].x -= 1;
        this.groups.rectangles[this.selectedShapeId].startPointX -= 1;
        this.groups.labels[this.selectedShapeId].x -= 1;
      }
      this.isAnythingUpdated = true;
    },

    moveSelectedNodesright() {
      if (this.isAllNodeSelected) {
        const selectedShapeNames = Object.keys(this.groups.rectangles);
        selectedShapeNames.forEach((shapeName) => {
          this.groups.rectangles[shapeName].x += 1;
          this.groups.rectangles[shapeName].startPointX += 1;
          this.groups.labels[shapeName].x += 1;
        });
      } else {
        this.groups.rectangles[this.selectedShapeId].x += 1;
        this.groups.rectangles[this.selectedShapeId].startPointX += 1;
        this.groups.labels[this.selectedShapeId].x += 1;
      }
      this.isAnythingUpdated = true;
    },
    resetLayerScale() {
      this.layerScale = 1;
      this.isLayerDragging = false;
    },

    updateLayerBounds(pos) {
      const stage = this.$refs.stage.getStage();
      const scaleX = stage.width() * (1 - this.layerScale);
      const scaleY = stage.height() * (1 - this.layerScale);

      const x = Math.min(0, Math.max(pos.x, scaleX));
      const y = Math.min(0, Math.max(pos.y, scaleY));

      return {
        x,
        y,
      };
    },

    updateLayerZoom(value) {
      const layer = this.$refs.layer.getNode();
      layer.scale({
        x: value,
        y: value,
      });

      const pos = this.updateLayerBounds({ x: layer.x(), y: layer.y() });
      layer.x(pos.x);
      layer.y(pos.y);
    },

    handleWheel(e) {
      e.evt.preventDefault();
      this.isWheelScroll = true;
      const layer = this.$refs.layer.getNode();
      const oldScale = this.layerScale;

      const mousePointTo = {
        x:
          layer.getRelativePointerPosition().x / oldScale -
          layer.x() / oldScale,
        y:
          layer.getRelativePointerPosition().y / oldScale -
          layer.y() / oldScale,
      };

      let direction = e.evt.deltaY > 0 ? 1 : -1;
      // Zooming using trackpad, ctrlKey is always true
      if (!e?.evt?.ctrlKey) direction = -direction;
      else direction = -direction;
      const newScale =
        direction > 0 ? oldScale * this.scaleBy : oldScale / this.scaleBy;
      if (newScale <= 1) {
        this.resetLayerScale();
        return;
      }

      let _x =
        -(mousePointTo.x - layer.getRelativePointerPosition().x / newScale) *
        newScale;
      let _y =
        -(mousePointTo.y - layer.getRelativePointerPosition().y / newScale) *
        newScale;

      this.layerScale = newScale;
      const pos = this.updateLayerBounds({ x: _x, y: _y });
      layer.x(pos.x);
      layer.y(pos.y);
      this.isWheelScroll = false;
    },

    removeRuntimeObject() {
      if (this.newRect) {
        delete this.groups.rectangles[this.newRect.name];
        delete this.groups.labels[this.newRect.name];
        this.activeObjectArea = false;
        this.newRect = null;
        this.selectedShapeId = null;
        this.annotate = null;
      }
    },

    handleLayerMouseDown(e) {
      this.removeRuntimeObject();
      if (this.activeObjectArea && !this.newRect) {
        this.activeObjectArea = false;
      }
      if (this.drawFreeRectangle()) return;
      if (this.layerScale <= 1) {
        this.resetLayerScale();
        return;
      }
      this.activeObjectArea = false;
      this.isLayerDragging = true;
    },

    handleLayerMouseUp(e) {
      try {
        if (!this.isCompleteAnnotation(e)) return;
        if (this.isNewRectAdded && this.newRect) {
          this.selectedShapeId = this.newRect.name;
          this.handleFloatingBoatPosition(this.newRect);
          this.isNewRectAdded = false;
        }
      } catch (error) {
        console.log(error);
      }
    },

    isCompleteAnnotation(e) {
      const name = e.target?.name();
      if (!this.currentRectName || !name) return false;
      let position = {};
      if (name !== this.currentRectName) {
        position = this.getCurrentRectangleFromLayer() || {};
      }
      if (!Object.keys(position).length)
        position = {
          x: e.target?.x(),
          y: e.target?.y(),
        };

      this.updateRectPosition(position);
      this.resetObjectDrawing();
      this.isLayerDragging = false;
      this.isAnythingUpdated = true;
      // this.saveCurrentImageAnnotation(false, this.imageIndex);
      return true;
    },

    getCurrentRectangleFromLayer() {
      const layer = this.$refs.layer.getNode();
      if (!layer?.children?.length) return null;

      const group = layer.children.find(
        (group) => group.attrs.id === `${this.currentRectName + 1}`
      );
      if (!group) return null;

      const rect = group.children[0];
      return { x: rect?.attrs?.x, y: rect?.attrs?.y };
    },

    handleLayerFitWindow() {
      this.resetLayerScale();
    },

    shouldRemoveRectangle() {
      return !this.isDrawingRectangle() || this.shouldRemoveCurrentRectangle();
    },

    updateRectPosition(position) {
      if (this.shouldRemoveRectangle()) return;
      this.updateGroupItemsCoordinates(this.currentRectName, position);
      this.groups.labels[this.currentRectName].text = !this
        .isAnnotateObjectChecked
        ? ''
        : this.currentObjectName;
      if (!this.isAnnotateObjectChecked) {
        if (!this.annotateObjectStroke)
          this.annotateObjectStroke = this.assignStrokeColor();
        this.groups.rectangles[this.currentRectName].stroke =
          this.annotateObjectStroke;
      }
      if (this.isAnnotateObjectChecked) {
        const rectangle = this.groups.rectangles[this.currentRectName];
        this.groups.labels[this.currentRectName].text = this.currentObjectName;
        this.addObjectInLabeledList(rectangle);
      }
      this.isAnythingUpdated = true;
    },

    assignStrokeColor() {
      let color = getRandomColor();
      while (this.isStrokeExist(color)) color = getRandomColor();
      return color;
    },

    isStrokeExist(color) {
      for (const [key, value] of Object.entries(this.objectStrokeColor)) {
        if (value === color) return true;
      }
      return false;
    },

    shouldRemoveCurrentRectangle() {
      const rectangle = this.groups.rectangles[this.currentRectName];
      if (!rectangle) {
        this.deleteRectangle();
        return true;
      }
      if (rectangle.width !== 0 && rectangle.height !== 0) return false;
      this.deleteRectangle();
      return true;
    },

    deleteRectangle() {
      delete this.groups.rectangles[this.currentRectName];
      delete this.groups.labels[this.currentRectName];
      this.currentRectName = null;
      this.isAnythingUpdated = false;
    },

    debounce(func, wait) {
      return (...args) => {
        if (this.debounceTimeout) {
          clearTimeout(this.debounceTimeout);
        }
        this.debounceTimeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    },

    async updateStats() {
      await this.getTaskObjects(this.selectedTask);
    },

    async updateImageConfig() {
      const canvas = await this.getCanvasElement();
      const { width, height } = canvas.getBoundingClientRect();
      this.imageDimensions = {
        width,
        height,
      };
      this.stageSize = {
        width,
        height,
      };
    },

    setPrevShapes() {
      if (this.isHistoryApplied == false) {
        const currRects = this.groups.rectangles;
        if (Object.keys(currRects)?.length) {
          this.toast.error('Image is already annotated!');
          return;
        }
        if (!this.prevGroups?.length) {
          this.toast.error("There's no objects on previous image!");
          return;
        }
        this.resetInitState();
        this.updateGroups(this.prevGroups);
        this.isHistoryApplied = true;
        this.isAnythingUpdated = true;
      }
    },

    getCanvasElement() {
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          const canvas = document.getElementsByTagName('canvas')[0];
          if (canvas) {
            clearInterval(interval);
            resolve(canvas);
          }
        }, 1000);
      });
    },

    isDraggable(object) {
      return OBJECT.isDraggable(object);
    },

    isAnnotated() {
      return this.annotationFilePath !== null;
    },

    onObjectSelect(object) {
      const objName = object.name;
      this.selectedShapeName =
        this.selectedShapeName === objName ? null : objName;
      this.selectedShapeId = object.id ? null : object.id;
      this.isAddingRectangle = false;
      this.updateTransformer();
    },

    updateCurrentObjectName(id) {
      this.currentObjectId = id;
      this.currentObjectName = this.getObjectName(id);
    },

    updateSelectedObjectName(id) {
      this.currentObjectId = id;
      return this.getObjectName(id);
    },

    getObjectName(id) {
      const currentObj = this.annotateObjects.find((value) => value.id === id);
      if (currentObj) return currentObj.name;
      return null;
    },

    // add new object in groups
    setNewObjectConfigToDraw() {
      if (this.imageLoading || this.isObjectFetching) return;
      // if (!this.currentObjectName) {
      //   this.resetObjectDrawing();
      //   return;
      // }
      this.isAddingRectangle = !this.isAddingRectangle;
    },

    // get Image to Display
    async getImageUrlFromS3() {
      if (!this.imagePathInBucket) return;
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: this.imagePathInBucket,
      };
      const { presigned_url } = await httpClient.post(
        'generic/generate_new_url/',
        obj,
        false,
        false,
        false
      );
      return presigned_url;
    },

    // handleChangeImage(imgSrc){
    //   this.image =
    // },

    async loadImageRange(imageObj, image_index) {
      if (this.isCachingAnnotation) return; // Exit early if already caching
      this.handleDeselectAll(); // Deselect all items
      this.isCachingAnnotation = true; // Set caching state

      // Find the image index and update relevant states
      image_index = this.imageList.findIndex((val) => val.id === imageObj.id);
      this.imageIndexList = image_index;
      this.img_idx = image_index;

      const my_index = this.imageIndex;

      // Save the current image annotation before loading a new one
      await this.saveCurrentImageAnnotation(false, my_index);

      if (image_index !== my_index) {
        this.resetInitState(); // Reset only if the indices differ
      }
      if (
        !(image_index in this.imagePresignedUrl) &&
        !(image_index in this.annotationCache)
      ) {
        // const presigned_url_image = await this.getImageUrlFromS3Index(imageObj.path_to_image);
        // this.imagePresignedUrl[image_index] = presigned_url_image;

        // const presigned_url_annotation = await this.getImageUrlFromS3Index(imageObj.path_to_annotations);
        // this.annotationCache[image_index] = await this.getAnnotationFromUrl(presigned_url_annotation);
        // console.log("my_index",image_index)
        // console.log("this.imagePresignedUrl",this.imagePresignedUrl)
        // console.log("this.annotationCache",this.annotationCache)
        await this.cacheonSelect(image_index);
      }

      // Load the image and cache the selection
      this.$emit('prevImage', image_index - this.imageIndex);
      await this.loadImage(imageObj);

      // this.cacheImages(my_index);

      this.isCachingAnnotation = false; // Reset the caching state

      // Handle applying previous labels if necessary
      if (this.img_idx > 0) {
        const prevAnnotation = this.annotationCache[this.img_idx - 1];
        if (!prevAnnotation || prevAnnotation.length === 0) {
          this.isApplyPreviousLabel = true; // Set flag to apply previous label
        }
      }
    },
    async loadImage(imageObj) {
      return new Promise(async (resolve, reject) => {
        const img = new window.Image();
        img.src = this.imagePresignedUrl[this.imageIndex];
        img.onload = () => {
          this.image = img;
          this.image.width = img.naturalWidth;
          this.image.height = img.naturalHeight;
          resolve(this.imagePresignedUrl[this.imageIndex]);
        };
        img.onerror = () => {
          resolve(0);
        };
      });
    },

    // for rendering existing objects
    getObjectListFromState() {
      return this.taskObjectList.map((o) => {
        return {
          ...o,
          cordinates_of_static_object: parseStringCoordsToObj(
            o.cordinates_of_static_object
          ),
        };
      });
    },

    async getAnnotationFromUrl(presigned_url_annotation) {
      try {
        if (presigned_url_annotation) {
          const { data } = await axios.get(presigned_url_annotation);
          return data;
        }
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getObjectListFromS3() {
      try {
        let rectObjects = [];

        if (
          this.annotationCache[this.imageIndex] &&
          typeof this.annotationCache[this.imageIndex] === 'object' &&
          Object.keys(this.annotationCache[this.imageIndex]).length !== 0
        ) {
          // const { data } = await axios.get(
          //   this.annotationCache[this.imageIndex]
          // );
          const labeledAnnotations = this.annotationCache[
            this.imageIndex
          ]?.filter((o) =>
            this.nonStaticObjects.some((ns) => ns.id === o.actual_id)
          );
          rectObjects = labeledAnnotations
            ?.filter((o) => !o.hasOwnProperty('points'))
            ?.map(OBJECT.nestCoordinates);
        }

        return {
          rectObjects: rectObjects ? rectObjects : [],
        };
      } catch (error) {
        this.toast.error('Error fetching object list from S3: ' + error, 3000);
        return {
          rectObjects: [],
        };
      }
    },

    async renderExistingObjectList() {
      this.groups = { rectangles: {}, labels: {} };
      this.temp_groups = { rectangles: {}, labels: {} };
      const { rectObjects } = await this.getObjectListFromS3();
      this.updateGroups(rectObjects);
      this.createGroupClone();
      this.selectObject(0);
      this.updateTransformer();
    },

    async getObjectListFromS3History(index) {
      try {
        let rectObjects = [];

        if (
          this.annotationCache[index] &&
          typeof this.annotationCache[index] === 'object' &&
          Object.keys(this.annotationCache[index]).length !== 0
        ) {
          // const { data } = await axios.get(this.annotationCache[index]);
          const labeledAnnotations = this.annotationCache[index]?.filter((o) =>
            this.nonStaticObjects.some((ns) => ns.id === o.actual_id)
          );
          rectObjects = labeledAnnotations
            ?.filter((o) => !o.hasOwnProperty('points'))
            ?.map(OBJECT.nestCoordinates);
        }

        return {
          rectObjects: rectObjects ? rectObjects : [],
        };
      } catch (error) {
        this.toast.error('Error fetching object list from S3: ' + error, 3000);
        return {
          rectObjects: [],
        };
      }
    },

    async renderLastExistingObjectList() {
      this.groups = { rectangles: {}, labels: {} };
      this.temp_groups = { rectangles: {}, labels: {} };
      const { rectObjects } = await this.getObjectListFromS3History(
        this.imageIndex - 1
      );
      this.resetInitState();
      this.updateGroups(rectObjects);
      this.isAnythingUpdated = true;
    },

    selectObject(index) {
      this.$nextTick(() => {
        if (
          this.labeledObjectList.length &&
          index < this.labeledObjectList.length
        ) {
          // this.selectedShapeName = this.labeledObjectList[index]?.name;
          this.updateTransformer();
        }
      });
    },

    updateGroups(rectObjects) {
      if (!rectObjects.length) return;
      const s3Objects = rectObjects?.filter(
        (o) => !o.is_static && !o.hasOwnProperty('points')
      );
      const existingGroups = {
        ...this.createRectanglesFromExistingObjects([...s3Objects]),
      };
      this.setObjectColor(existingGroups.rectangles);
      this.groups = existingGroups;
      this.updateLabelSize();
      this.initializeLabeledObject();
    },

    initializeLabeledObject() {
      this.labeledObjectList = [];
      this.isObjectFetching = true;
      for (const key of Object.keys(this.groups.rectangles)) {
        this.addObjectInLabeledList(this.groups.rectangles[key]);
      }
      this.isObjectFetching = false;
    },

    setObjectColor(objects) {
      Object.values(objects).forEach((o) => {
        const objName = o.actual_name ? o.actual_name : o.name;
        o['stroke'] = this.objectStrokeColor[objName];
      });
    },

    async uploadAnnotationToS3(s3Objects, hidetoast, imageIndex) {
      const currentImageId = this.imageId;
      this.uploadQueue = this.uploadQueue
        .then(async () => {
          try {
            const { file_path, file_name } = await this.getAnnotationNamePath();
            const payload = this.getPayloadForUploading(
              s3Objects,
              file_path,
              file_name
            );

            const fileUploadResult = await DetectorService.uploadObjectFile(
              currentImageId,
              payload,
              false
            );
            if (fileUploadResult[1] == null) {
              this.toast.error('Failed to save annotations', 3000);
              hidetoast = true;
            }
            if (Object.keys(s3Objects).length != 0) {
              const imageAnnotationResult =
                await DetectorService.updateImageAnnotation(
                  { path_to_annotations: file_path },
                  currentImageId,
                  false
                );
            }
            // const presigned_url_annotation = await this.getImageUrlFromS3Index(
            //   imageAnnotationResult[1].path_to_annotations
            // );
            // this.annotationCache[imageIndex] = await this.getAnnotationFromUrl(
            //   presigned_url_annotation
            // );

            this.$emit(
              'updateAnnotationPath',
              this.getAnnotationPathForState(file_path)
            );
          } catch (error) {
            this.toast.error('Failed to save annotations', 3000);
            hidetoast = true;
            console.error('Error in uploadAnnotationToS3:', error);
          }
        })
        .catch((error) => {
          console.error('Error in upload queue:', error);
        });
    },

    getAnnotationPathForState(file_path) {
      const rects = Object.keys(this.groups.rectangles);
      if (!rects.length) return null;
      else return file_path;
    },

    async getAnnotationNamePath() {
      // if (this.annotationFilePath)
      //   return {
      //     file_path: this.annotationFilePath,
      //     file_name: this.annotationFileName
      //   };

      const pathSplit = this.imagePathInBucket.split('/');
      const imageFileName = pathSplit.at(-1);
      return {
        file_path: `${pathSplit
          .slice(0, 2)
          .join('/')}/labels/${imageFileName}.json`,
        file_name: `${imageFileName}.json`,
      };
    },

    getPayloadForUploading(s3Objects, file_path, file_name) {
      const fileAsJSON = JSON.stringify(s3Objects);
      const blob = new Blob([fileAsJSON], { type: 'application/json' });
      const data = new FormData();
      data.append('file', fileAsJSON);
      data.append('file_path', file_path);
      data.append('bucket', this.organization + '-training');
      return data;
    },

    changeLabelColor(isActive) {
      if (isActive) return;
      if (this.textColor === 'white') this.textColor = 'black';
      else this.textColor = 'white';
    },

    async preloadImageandAnnotation() {
      var presigned_url = this.presignedUrlList;
      if (Object.keys(this.presignedUrlList.image)[0] != this.startIndex) {
        presigned_url = await this.generateBulkImageS3Url(
          this.startIndex,
          this.endIndex,
          'annotation'
        );
      }
      const annotationPromises = Object.keys(presigned_url.annotation).map(
        async (key) => {
          this.annotationCache[key] = await this.getAnnotationFromUrl(
            presigned_url.annotation[key]
          );
        }
      );

      await Promise.all(annotationPromises);
      this.imagePresignedUrl = presigned_url.image;
    },

    async cacheImages(change_index) {
      if (this.isCachingImage) return; // Prevent concurrent operations
      this.isCachingImage = true;

      const image_list_length = Object.entries(this.imageList).length;

      if (change_index > 0) {
        await this.cacheForwardImages(image_list_length);
      } else if (change_index < 0) {
        await this.cacheBackwardImages(image_list_length);
      }

      this.isCachingImage = false;
    },

    async cacheForwardImages(image_list_length) {
      const image_keys = Object.keys(this.imagePresignedUrl);
      const annotation_keys = Object.keys(this.annotationCache);
      const length = image_keys.length;
      const lastIndex = Number(image_keys[length - 1]);

      if (lastIndex <= image_list_length && lastIndex - this.imageIndex <= 5) {
        const start_index = lastIndex + 1;
        const end_index = Math.min(start_index + 9, image_list_length);
        if (start_index != end_index) {
          await this.fetchNewImages(start_index, end_index, true);
        }
      }
    },

    async cacheBackwardImages(image_list_length) {
      const image_keys = Object.keys(this.imagePresignedUrl);
      const annotation_keys = Object.keys(this.annotationCache);
      const first_index = Number(image_keys[0]);

      if (first_index > 0 && this.imageIndex - first_index <= 5) {
        const end_index = first_index;
        const start_index = Math.max(end_index - 10, 0);
        if (start_index != end_index) {
          await this.fetchNewImages(start_index, end_index, false);
        }
      }
    },

    async fetchNewImages(start_index, end_index, removeStart) {
      const presigned_url = await this.generateBulkImageS3Url(
        start_index,
        end_index
      );
      const object_keys = Object.keys(presigned_url.image);

      object_keys.forEach(async (key) => {
        this.imagePresignedUrl[key] = presigned_url.image[key];
        this.annotationCache[key] = await this.getAnnotationFromUrl(
          presigned_url.annotation[key]
        );
      });

      if (removeStart) {
        this.removeOldKeys('shift');
      } else {
        this.removeOldKeys('pop');
      }
    },

    removeOldKeys(method) {
      const image_keys = Object.keys(this.imagePresignedUrl);
      const annotation_keys = Object.keys(this.annotationCache);

      for (let i = 0; i < 10; i++) {
        delete this.imagePresignedUrl[image_keys[method]()];
        delete this.annotationCache[annotation_keys[method]()];
      }
    },

    async cacheonSelect(my_index) {
      const image_list_length = Object.entries(this.imageList).length;
      let { start_index, end_index } = await this.calculateIndexes(
        my_index,
        image_list_length
      );
      //
      await this.updateCache(start_index, end_index);
      // // Ensure indexes are within bounds
      // start_index = Math.max(start_index, 0);
      // end_index = Math.min(end_index, image_list_length - 1);

      // const pre_image_keys = Object.keys(this.imagePresignedUrl);
      // const condition_test =  pre_image_keys.includes(start_index.toString()) &&
      // pre_image_keys.includes(end_index.toString())
      // console.log("pre_image_keys", pre_image_keys)
      // console.log("condition", condition_test)
      // console.log("start_index", start_index)
      // console.log("end_index", end_index)
      // console.log("this.imageIndex", this.imageIndex)
      // if (this.shouldUpdateCache(start_index, end_index, pre_image_keys)) {

      // }
    },

    async calculateIndexes(imageIndex, image_list_length) {
      let start_index = imageIndex - 15;
      let end_index = imageIndex + 15;

      // Ensure start_index is not less than 0
      start_index = Math.max(start_index, 0);

      // Adjust end_index if start_index is at the beginning of the list
      if (start_index === 0) {
        end_index = Math.min(30, image_list_length); // Make sure it doesn't exceed the list length
      } else {
        // Ensure end_index is within bounds
        end_index = Math.min(end_index, image_list_length);

        // If the range between start and end is less than 30, adjust start_index accordingly
        if (end_index - start_index < 30) {
          start_index = Math.max(0, end_index - 30);
        }
      }

      return { start_index, end_index };
    },

    shouldUpdateCache(start_index, end_index, pre_image_keys) {
      return !(
        pre_image_keys.includes(start_index.toString()) &&
        pre_image_keys.includes(end_index.toString())
      );
    },

    async updateCache(start_index, end_index) {
      // if (
      //   pre_image_keys.includes(start_index.toString()) &&
      //   start_index !== this.imageIndex
      // ) {
      //   start_index = Number(pre_image_keys.pop());
      // } else if (
      //   pre_image_keys.includes(end_index.toString()) &&
      //   end_index !== this.imageIndex
      // ) {
      //   end_index = Number(pre_image_keys.shift());
      // } else {
      //   this.resetCaches();
      // }

      const presigned_url = await this.generateBulkImageS3Url(
        start_index,
        end_index
      );
      this.applyPresignedUrls(presigned_url);
    },

    resetCaches() {
      this.imagePresignedUrl = {};
      this.annotationCache = {};
    },

    async applyPresignedUrls(presigned_url) {
      const object_keys = Object.keys(presigned_url.image);
      const image_keys = Object.keys(this.imagePresignedUrl);
      const annotation_keys = Object.keys(this.annotationCache);

      object_keys.forEach(async (key) => {
        this.imagePresignedUrl[key] = presigned_url.image[key];
        this.annotationCache[key] = await this.getAnnotationFromUrl(
          presigned_url.annotation[key]
        );
        if (image_keys.length)
          delete this.imagePresignedUrl[image_keys.shift()];
        if (annotation_keys.length)
          delete this.annotationCache[annotation_keys.shift()];
      });
      // for (const key of object_keys) {
      //   this.imagePresignedUrl[key] = presigned_url.image[key];
      //   if (image_keys.length)
      //     delete this.imagePresignedUrl[image_keys.shift()];
      //   this.annotationCache[key] = await this.getAnnotationFromUrl(
      //     presigned_url.annotation[key]
      //   );
      //   if (annotation_keys.length)
      //     delete this.annotationCache[annotation_keys.shift()];
      // }
    },

    async getObjectListCacheFromS3(objectPath) {
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: objectPath,
      };
      const res = await httpClient.post(
        'generic/generate_new_url/',
        obj,
        false,
        false,
        false
      );
      let { data } = await axios.get(res.presigned_url);

      return { data };
    },

    async generateImageS3Url(image_path) {
      if (!image_path) return;

      const obj = {
        bucket_name: this.organization + '-training',
        object_path: image_path,
      };

      try {
        const response = await httpClient.post(
          'generic/generate_new_url/',
          obj,
          false,
          false,
          false
        );
        const presigned_url = response; // Assuming response structure
        return presigned_url;
      } catch (error) {
        this.toast.error('Error generating presigned URL ' + error, 3000);
      }
    },

    async getImageUrlFromS3Index(image_path, image_index, imageObj) {
      const obj = {
        bucket_name: this.organization + '-training',
        object_path: image_path,
      };
      try {
        const response = await httpClient.post(
          'generic/generate_new_url/',
          obj,
          true,
          false,
          false
        );
        const presigned_url = response.presigned_url;
        return presigned_url;
      } catch (error) {
        this.toast.error('Error fetching presigned URL ' + error, 3000);
      }
    },

    async generateBulkImageS3Url(startIndex, endIndex, type) {
      const obj = {
        bucket_name: this.organization + '-training',
        task_id: this.taskId,
        start_index: startIndex,
        end_index: endIndex,
      };

      try {
        const response = await httpClient.post(
          'generic/generate_list_new_url/',
          obj,
          false,
          false,
          false
        );
        const presigned_url = response.presigned_url; // Assuming response structure

        return presigned_url;
      } catch (error) {
        this.toast.error('Error generating presigned URL ' + error, 3000);
      }
    },

    async deleteObject() {
      let index = 0;
      if (this.isAllNodeSelected) {
        const selectedShapeNames = Object.keys(this.groups.rectangles);

        if (selectedShapeNames.length === 0) return;

        for (const shapeName of selectedShapeNames) {
          if (!this.groups.rectangles[shapeName]) continue;
          delete this.groups.rectangles[shapeName];
          delete this.groups.labels[shapeName];

          this.labeledObjectList = this.labeledObjectList.filter(
            (obj) => obj.name !== shapeName
          );
        }
      } else {
        delete this.groups.rectangles[this.selectedShapeName];
        delete this.groups.labels[this.selectedShapeName];
        index = this.labeledObjectList.findIndex(
          (obj) => obj.name === this.selectedShapeName
        );
        this.labeledObjectList = this.labeledObjectList.filter(
          (obj) => obj.name !== this.selectedShapeName
        );
      }
      this.selectedShapeName = null;
      this.isAnythingUpdated = true;
      this.isAddingRectangle = false;
      this.deleteShow = false;

      this.trySelectNextShape(index); // Attempt to select the next shape, starting from the beginning
      this.updateTransformer();
      this.handleDeselectAll();
      this.isAnythingUpdated = true;
      // this.saveCurrentImageAnnotation(false, this.imageIndex);
    },

    trySelectNextShape(index) {
      if (index === -1) return;
      if (index && index - this.labeledObjectList.length === 0) index -= 1;
      if (index < this.labeledObjectList.length)
        this.selectedShapeName = this.labeledObjectList[index].name;
    },

    handleDeleteMenuShow(e) {
      e.evt?.preventDefault();
      if (!e.target.hasOwnProperty('parent')) {
        return;
      }
      const pos = this.$refs.stage.getStage().getPointerPosition();
      const selectedObjectName = this.getObjectTargetName(e);
      if (selectedObjectName === null) return;
      this.showDeleteContextMenu(pos, selectedObjectName);
    },

    getObjectTargetName(e) {
      let name = e.target?.name();
      if (name && name !== this.imageConfig.name) {
        return this.getSelectedObjectName(e);
      }
      return null;
    },

    getSelectedObjectName(e) {
      if (!e.target?.id() || !e.target?.name()) return null;
      const idText = e.target.id().split('-').slice(-1)[0];
      if (idText.toLowerCase() !== 'text') return e.target.name();
      const groupChildren = e.target.parent?.children;
      if (groupChildren?.length) {
        const rect = groupChildren[0];
        return rect?.attrs?.name;
      }
      return null;
    },

    showDeleteContextMenu(pos, name) {
      this.activeObjectArea = false;
      let deleteContextMenu = document.getElementById('deleteContextMenu');
      deleteContextMenu.style.top = `${pos.y + 10}px`;
      deleteContextMenu.style.left = `${pos.x - 25}px`;
      const rect = this.groups.rectangles[name];
      if (rect) {
        this.selectedShapeName = name;
        this.deleteShow = true;
      }
    },

    handleStageClick(e) {
      e.evt?.preventDefault();
      if (e.evt?.button === 2) return;
      const deleteContextMenu = document.getElementById('deleteContextMenu');
      if (deleteContextMenu.style.display === 'initial') {
        this.selectedShapeName = null;
        this.deleteShow = false;
      }
      if (!e?.evt?.ctrlKey) {
        this.handleDeselectAll();
      }
    },

    resetObjectDrawing() {
      this.isDrawing = false;
      this.isAddingRectangle = false;
    },

    handleLayerMouseMove(e) {
      this.handleMouseMoveDrawRectangle();
    },

    handleMouseMoveDrawRectangle() {
      if (!this.isDrawingRectangle()) return;

      const { rectangles } = this.groups;
      const currentRect = rectangles[this.currentRectName];
      if (!currentRect) return;
      const point = this.$refs.layer.getNode().getRelativePointerPosition();
      currentRect.width = point.x - currentRect.startPointX;
      currentRect.height = point.y - currentRect.startPointY;
      if (!this.isAnnotateObjectChecked) {
        // this.handleFloatingBoatPosition(currentRect)
        this.newRect = currentRect;
        this.isNewRectAdded = true;
      }
    },

    handleFloatingBoatPosition(currentRect) {
      // Get the container element of the Konva stage
      const layerNode = this.$refs.layer.getNode();
      const container = layerNode.getStage().container();

      // Get the bounding rectangle of the container
      const layerRect = container.getBoundingClientRect();

      // Calculate default bottom-right point relative to the layer
      const defaultBottomRightX =
        currentRect.startPointX + currentRect.width + 50;
      const defaultBottomRightY =
        currentRect.startPointY + currentRect.height + 50;

      // Fixed dimensions of the floating card
      const floatingCardWidth = 200;
      const floatingCardHeight = 94;

      // Determine the final position of the floating card
      let finalX = defaultBottomRightX;
      let finalY = defaultBottomRightY;

      // Check if the floating card goes out of the right boundary
      if (defaultBottomRightX + floatingCardWidth > layerRect.width) {
        // Place the card at the top of the rectangle
        finalX =
          currentRect.startPointX + currentRect.width - floatingCardWidth;
      }

      // Check if the floating card goes out of the bottom boundary
      if (defaultBottomRightY + floatingCardHeight > layerRect.height) {
        // Place the card at the top of the rectangle
        finalY = currentRect.startPointY - floatingCardHeight;
      }

      // Position the floating card
      this.$refs.floatingCard.style.top = `${finalY}px`;
      this.$refs.floatingCard.style.left = `${finalX}px`;
      this.$refs.floatingCard.style.width = `${floatingCardWidth}px`;
      this.$refs.floatingCard.style.height = `fit-content`;
      this.$refs.floatingCard.style.zIndex = 900;
      this.deleteShow = false;
      this.activeObjectArea = true;
      this.searchValue = null;
      this.annotate = null;
      this.$nextTick(() => this.$refs.annotateSelect?.focus());
    },

    isDrawingRectangle() {
      return this.isDrawing;
    },

    drawFreeRectangle() {
      if (!this.isAddingRectangle || this.isDrawing) return;
      this.isDrawing = true;
      const object = {
        cordinates_of_static_object: null,
        is_static: false,
        name: !this.isAnnotateObjectChecked ? '' : this.currentObjectName,
        actual_id: this.currentObjectId,
        id: uuid.v4(),
      };

      let { rectangle, label } = this.createRectLabelGroup(object);
      const pos = this.$refs.layer.getNode().getRelativePointerPosition();
      rectangle.startPointX = pos.x;
      rectangle.startPointY = pos.y;
      rectangle.actual_name = this.currentObjectName;
      rectangle.x = -1;
      rectangle.y = -1;
      rectangle.width = 0;
      rectangle.height = 0;
      label.text = null;
      label.fontSize = this.currentLabelSize;
      label.x = rectangle.startPointX;
      label.y =
        this.getLabelVerticalCoordinate(rectangle.name) ??
        this.getDefaultLabelCoordinate(rectangle.startPointY);
      rectangle.stroke = this.objectStrokeColor[this.currentObjectName];

      const newGroups = this.groups;
      this.currentRectName = rectangle.name;
      newGroups.rectangles[rectangle.name] = {
        ...rectangle,
      };
      if (!this.isAnnotateObjectChecked) {
        this.annotateObjectStroke = this.assignStrokeColor();
        newGroups.rectangles[rectangle.name].stroke = this.annotateObjectStroke;
      }
      newGroups.labels[rectangle.name] = { ...label };
      return true;
    },

    getDefaultLabelCoordinate(pointY) {
      return pointY - this.labelVerticalGap;
    },

    addObjectInLabeledList(rectangle) {
      if (
        rectangle.name == '' ||
        rectangle.actual_name == '' ||
        rectangle.id == ''
      )
        return;
      if (this.labeledObjectList.find((data) => data.id == rectangle.id)) {
        this.labeledObjectList = this.labeledObjectList.filter(
          (data) => data.id !== rectangle.id
        );
      }
      this.labeledObjectList.push({
        name: rectangle.name,
        actual_name: rectangle.actual_name,
        id: rectangle.id,
      });
    },

    // set Transformer when click on stage
    handleStageMouseDown(e) {
      if (this.isDrawingRectangle()) return;

      if (!e.target.hasOwnProperty('parent')) {
        return;
      }

      const clickedOnTransformer =
        e.target.getParent()?.className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      const name = e.target.name();
      const rect = this.groups.rectangles[name];

      this.selectedShapeName = rect ? name : '';
      this.updateTransformer();
    },

    async changeImage(index) {
      await this.saveCurrentImageAnnotation(false, this.imageIndex);
      this.resetInitState();
      this.img_idx += index;
      this.resetChangeImage(index);
      this.$emit('prevImage', index);
      if (
        this.annotationCache[this.img_idx - 1] == null ||
        this.annotationCache[this.img_idx - 1] === '' ||
        this.annotationCache[this.img_idx - 1].length === 0
      ) {
        this.isApplyPreviousLabel = true;
      }
    },

    resetChangeImage(index) {
      this.resetLayerScale();
      this.imageIndexList = this.imageIndexList + index;
      this.cacheImages(index);
      this.selectedShapeName = null;
      this.selectedShapeId = null;
      this.isAddingRectangle = false;
      this.updateTransformer();
      this.handleDeselectAll();
    },

    resetInitState() {
      // cleanup
      this.resetTransform();
      this.groups = { rectangles: {}, labels: {} };
      this.deleteShow = false;
      this.activeObjectArea = false;
      this.isAddingRectangle = false;
      this.isHistoryApplied = false;
      this.isObjectFetching = false;
      this.isAnythingUpdated = false;
      this.annotate = null;
      this.searchValue = null;
      this.temp_groups = {
        rectangles: {},
        labels: {},
      };
      this.isDrawing = false;
      this.labeledObjectList = [];
      this.selectedShapeName = null;
      this.selectedShapeId = -1;
      this.isApplyPreviousLabel = false;
    },

    shouldUpdateFile() {
      return JSON.stringify(this.groups) !== JSON.stringify(this.temp_groups);
    },

    async saveCurrentImageAnnotation(
      hidetoast = false,
      imageIndex = this.imageIndex
    ) {
      if (this.newRect) {
        delete this.groups.rectangles[this.newRect.name];
        delete this.groups.labels[this.newRect.name];
        this.activeObjectArea = false;
        this.newRect = null;
        this.selectedShapeId = null;
        this.annotate = null;
        this.selectedShapeName = '';
      }
      // if(this.selectedShapeName){
      //   this.updateTransformer();
      // }
      this.resetObjectDrawing();
      if (!this.shouldUpdateFile() || !this.isAnythingUpdated) {
        return;
      }
      const newAnnotations = this.getAnnotation();
      this.annotationCache[this.imageIndex] = newAnnotations;
      await this.uploadAnnotationToS3(newAnnotations, hidetoast, imageIndex);
      this.createGroupClone();
      this.isAnythingUpdated = false;
    },

    createGroupClone() {
      this.temp_groups = deepClone(this.groups);
    },

    // getAnnotation() {
    //   let annotationList = [];
    //   const tempAnnotationsGroups = deepClone(this.groups);
    //   const { rectangles, labels } = tempAnnotationsGroups;
    //   const { width, height } = this.imageDimensions;
    //   for (const [name, rectangle] of Object.entries(rectangles)) {
    //     this.normalizeDim(rectangle, width, height);
    //     annotationList.push({
    //       ...rectangle,
    //       name: labels[name].text
    //     });
    //   }
    //   return annotationList.map(OBJECT.filterRectanglePropsFromObject);
    // },
    getAnnotation() {
      const tempAnnotationsGroups = deepClone(this.groups);
      const { rectangles, labels } = tempAnnotationsGroups;
      let annotationList = [];
      for (const [name, rectangle] of Object.entries(rectangles)) {
        const serializedRectangle = this.serializeNodeToObject(name);
        let annotationObject = {
          ...rectangle,
          ...serializedRectangle,
          name: labels[name].text,
        };
        this.roundObjectValuesToDecimal(annotationObject, 3);
        annotationList.push(annotationObject);
      }
      return annotationList.map(OBJECT.filterRectanglePropsFromObject);
    },

    roundObjectValuesToDecimal(object, round) {
      const keys = Object.keys(object);
      keys.forEach((key) => {
        if (typeof object[key] === 'number')
          object[key] = parseFloat(object[key].toFixed(round));
      });
    },

    normalizeDim(object, normX, normY) {
      object.x = object.x / normX;
      object.y = object.y / normY;
      object.width = object.width / normX;
      object.height = object.height / normY;
    },

    changeLabelSize(size) {
      this.currentLabelSize = size;
      this.updateLabelSize();
    },

    updateLabelSize() {
      const { labels } = this.groups;
      const newLabels = Object.entries(labels).reduce((res, [key, value]) => {
        res[key] = {
          ...value,
          fontSize: this.currentLabelSize,
          y: this.getLabelVerticalCoordinate(key) ?? value.y,
        };
        return res;
      }, {});
      this.groups['labels'] = newLabels;
    },
  },
};
</script>
<style scoped>
.card-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px;
}
.annotation-image-card {
  display: flex !important;
  flex-direction: row !important;
  margin: 0px !important;
}
.annotation-image-card :deep(.ant-card-head) {
  display: flex !important;
  flex-direction: column !important;
  width: 80px;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  max-height: 75vh;
  border: none;
}

#annotation-object > .ant-card-cover {
  padding: 0px !important;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content {
  width: unset !important;
  height: unset !important;
  flex-grow: 1 !important;
  display: flex;
}

.annotation-image-card > .ant-card-cover > div > .konvajs-content > canvas {
  border-radius: 0px !important;
  flex-grow: 1;
  width: 100% !important;
  height: 100% !important;
}

.objects-list {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  height: 35% !important;
}

.objects-list :deep(.ant-list-header) {
  background: lightgray !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.objects-list :deep(.ant-list-header span) {
  font-weight: 600 !important;
}

.objects-list :deep(.ant-list-header p) {
  margin: 0;
  font-size: 11px;
}

.objects-list :deep(.ant-spin-nested-loading) {
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
}

/* .entity-list-item:hover {
  background: rgb(231, 231, 231);
} */

.selected-object {
  background: rgb(241, 238, 238);
}

.color-box {
  width: 20px;
  height: 10px;
  display: inline-block;
  background: red;
}
</style>
