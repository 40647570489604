<template>
  <div class="h-100 d-flex flex-column" :style="mainComponentStyle">
    <a-card
      id="mark-objects-card"
      class="h-100 d-flex flex-column"
      size="small"
      :body-style="cardBodyStyle"
      :loading="loadingImages || isBulkDelete"
    >
      <!-- <template> -->
      <!-- <a-spin :spinning="imageSpinner" size="large" tip="Loading Images..."> -->
      <div v-if="imageSpinner" class="spinner-container">
        <a-spin size="large" :spinning="imageSpinner" tip="Loading Images..." />
      </div>
      <!-- </a-spin> -->
      <!-- </template> -->

      <!-- selectedImage -->
      <DrawObjects
        v-if="selectedImage"
        class="draw-objects-card"
        ref="drawObjects"
        :image-path-in-bucket="requestedImagePath"
        :image-list="imagesForAnnotation"
        :task-id="selectedTask"
        :annotation-file-name="requestImageAnnotationName"
        :annotation-file-path="requestImageAnnotationPath"
        :image-id="requestedImageId"
        :image-obj="selectedImage"
        :image-index="currentImageIndex"
        :total-results="totalImagesForAnnotation"
        :current-page="currentPageForImage"
        :page-size="pageSizeForImage"
        :start-index="startIndexCache"
        :end-index="endIndexCache"
        :presigned-url-list="presignedUrlList"
        @closeModal="selectedImage = null"
        @nextImage="handleImageChange"
        @prevImage="handleImageChange"
        @updateAnnotationPath="updateAnnotationPath"
      />
      <template #title>
        <div class="d-flex align-items-center">
          <a-button
            v-if="selectedImage"
            class="text-dark"
            type="link"
            @click="selectedImage = null"
          >
            <template #icon>
              <arrow-left-outlined />
            </template>
          </a-button>
          <template v-if="!selectedImage">
            <a-checkbox
              v-model:checked="selectAllImages"
              class="m-1"
              @change="handleSelectAllFiles"
              :disabled="
                imageFileList.length === 0 || loadingImages || isBulkDelete
              "
            />
            <a-space>
              <a-typography-title :level="5" class="my-0 ml-3">
                Total Images: &nbsp;
                <a-tag id="annotation-objects-count" color="blue">
                  {{ totalImagesForAnnotation }}
                </a-tag>
              </a-typography-title>

              <a-typography-title
                v-if="getSelectedImagesCount"
                :level="5"
                class="my-0 ml-3"
              >
                Selected Images: &nbsp;
                <a-tag id="annotation-objects-count" color="blue">
                  {{ getSelectedImagesCount }}
                </a-tag>
              </a-typography-title>
            </a-space>
            <div class="w-100 d-flex justify-content-center">
              <a-input
                placeholder="Search Image"
                v-model:value="searchImage"
                class="w-50"
                @input="debouncedSearch"
                @keyup.enter="debouncedSearch"
                :disabled="loadingImages || isBulkDelete"
              >
                <template #suffix>
                  <search-outlined style="color: rgba(0, 0, 0, 0.45)" />
                </template>
              </a-input>
            </div>
          </template>
          <a-typography-title v-else :level="5" class="mx-auto">
            {{ getFileName(selectedImage.path_to_image) }}
          </a-typography-title>
        </div>
      </template>

      <template v-if="!selectedImage" #extra>
        <a-space>
          <a-switch
            v-if="displayStaticSwitch"
            v-model:checked="markStatic"
            checked-children="static"
            un-checked-children="non-static"
            @change="handleMarkStaticChanged"
          />
          <a-tooltip title="Object Count Statistics">
            <a-button
              id="images-stats-btn"
              type="default"
              size="small"
              @click="visible = true"
            >
              <template #icon>
                <BarChartOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <a-tooltip title="Reload Images">
            <a-button
              id="refresh-images-btn"
              type="default"
              size="small"
              @click="reloadImages"
            >
              <template #icon>
                <reload-outlined />
              </template>
            </a-button>
          </a-tooltip>

          <a-tooltip title="Change View">
            <a-button
              id="change-view-btn"
              type="default"
              size="small"
              @click="handleChangeView"
            >
              <template #icon>
                <FolderViewOutlined />
              </template>
            </a-button>
          </a-tooltip>

          <template v-if="!shouldDeleteDisabled">
            <a-tooltip title="Delete">
              <a-button
                id="delete-all-btn"
                type="danger"
                size="small"
                @click="handleDelete"
                :disabled="isBulkDelete || loadingImages"
              >
                <template #icon>
                  <DeleteOutlined />
                </template>
              </a-button>
            </a-tooltip>
          </template>

          <a-tooltip v-if="userRole" title="Upload Image File">
            <a-button
              id="upload-images-btn"
              type="default"
              size="small"
              @click="fileUpload = true"
            >
              <template #icon>
                <UploadOutlined />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>

      <template #actions>
        <a-pagination
          v-if="!selectedImage && !isBulkDelete"
          id="object-detection-pagination"
          :total="totalImagesCount"
          v-model:current="currentPageForImage"
          v-model:pageSize="pageSizeForImage"
          :show-total="
            (total, range) => `${range[0]}-${range[1]} of ${total} Frames`
          "
          class="w-100 d-flex justify-content-center"
          :page-size-options="[30, 50, 100, 150]"
          @change="handlePageChange"
          @show-size-change="handlePageSizeChange"
        />
      </template>
      <a-list
        v-if="!selectedImage"
        item-layout="horizontal"
        :grid="
          listView
            ? null
            : {
                gutter: 32,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 3,
                xl: 4,
                xxl: 4,
                xxxl: 5,
              }
        "
        :data-source="imageFileList"
        :id="!listView ? 'label-objects-list' : 'label-objects-list-view'"
        class="list-pagination-display"
        :pagination="{
          pageSize: pageSizeForImage,
          current: currentPageForImage,
          total: totalImagesCount,
        }"
      >
        <template #renderItem="{ item, index }">
          <a-list-item
            v-if="listView"
            class="cursor-pointer file-item"
            :key="item.id"
            @click="openAnnotationModal(item)"
          >
            <template #actions>
              <a-tag
                v-if="staticImage[item.id]"
                :key="'list-edit-static-' + item.id"
                color="cyan"
              >
                Static
              </a-tag>
              <a-tag
                v-if="item.path_to_annotations"
                :key="'list-edit-' + item.id"
                color="green"
                >Annotated</a-tag
              >
            </template>
            <div>
              <a-checkbox
                v-model:checked="selectImage[item.id]"
                class="m-3"
                @click.stop
              />
              <a-typography-title
                :level="4"
                class="ml-1"
                style="display: inline"
              >
                {{ getFileName(item.path_to_image) }}
              </a-typography-title>
            </div>
          </a-list-item>
          <template v-else>
            <a-list-item class="objects-list-item" :key="item.id">
              <a-badge-ribbon
                v-if="item.path_to_annotations"
                color="#87d068"
                text="Annotated"
              >
                <a-badge-ribbon
                  v-if="staticImage[item.id]"
                  color="cyan"
                  text="Static"
                  placement="start"
                >
                  <preview-image-card
                    :imageObj="item"
                    :presignedUrl="presignedUrlList"
                    :index="getImagetItemIndex(item)"
                    :select-all="selectImage"
                    :get-file-name="getFileName"
                    :open-annotation-modal="openAnnotationModal"
                    :delete-annotation="deleteAnnotation"
                    @update-select-all="updateSelectAll"
                  />
                </a-badge-ribbon>
                <preview-image-card
                  v-else
                  :imageObj="item"
                  :presignedUrl="presignedUrlList"
                  :index="getImagetItemIndex(item)"
                  :select-all="selectImage"
                  :get-file-name="getFileName"
                  :open-annotation-modal="openAnnotationModal"
                  :delete-annotation="deleteAnnotation"
                  @update-select-all="updateSelectAll"
                />
              </a-badge-ribbon>
              <template v-else>
                <a-badge-ribbon
                  v-if="staticImage[item.id]"
                  color="cyan"
                  text="Static"
                  placement="start"
                >
                  <preview-image-card
                    :imageObj="item"
                    :presignedUrl="presignedUrlList"
                    :index="getImagetItemIndex(item)"
                    :select-all="selectImage"
                    :get-file-name="getFileName"
                    :open-annotation-modal="openAnnotationModal"
                    :delete-annotation="deleteAnnotation"
                    @update-select-all="updateSelectAll"
                /></a-badge-ribbon>
                <preview-image-card
                  v-else
                  :imageObj="item"
                  :presignedUrl="presignedUrlList"
                  :index="getImagetItemIndex(item)"
                  :select-all="selectImage"
                  :get-file-name="getFileName"
                  :open-annotation-modal="openAnnotationModal"
                  :delete-annotation="deleteAnnotation"
                  @update-select-all="updateSelectAll"
                />
              </template>
            </a-list-item>
          </template>
        </template>
      </a-list>
    </a-card>
  </div>
  <a-modal
    :visible="visible"
    title="Total Labeled Objects"
    :footer="null"
    :body-style="{ maxHeight: '400px', overflow: 'auto' }"
    @cancel="visible = false"
    class="p-1 pb-3"
  >
    <a-list
      size="small"
      :data-source="[...annotatedObjectList]"
      :loading="annotatedObjectList.length == 0"
    >
      <template #renderItem="{ item }">
        <a-list-item v-if="!item.is_static">
          <div>
            {{ item.name }}
          </div>
          <div>
            {{ item.count }}
          </div>
        </a-list-item>
      </template>
    </a-list>
  </a-modal>

  <a-modal
    :visible="fileUpload"
    title="Upload Image File"
    centered
    @cancel="handleCancelUploadModal"
  >
    <template #footer>
      <a-button
        type="primary"
        :disabled="fileList.length === 0"
        :loading="isUploadingFile"
        @click="handleUploadImageFile"
      >
        {{
          isUploadingFile
            ? fileUploadProgress > 0 && fileUploadProgress < 100
              ? 'Extracting'
              : uploadingZipDetails >= 100
              ? 'Extraction begin'
              : 'Uploading'
            : fileUploadProgress === 100
            ? 'Close'
            : 'Upload'
        }}
      </a-button>
    </template>
    <a-upload
      :disabled="isUploadingFile"
      v-model:file-list="fileList"
      accept=".zip"
      name="file"
      :maxCount="1"
      :before-upload="(f) => false"
      @change="handleUploadFile"
    >
      <a-space>
        <a-button :disabled="isUploadingFile">
          <upload-outlined></upload-outlined>
          Click to Upload Zip File
        </a-button>
        <span>File Limit: 1 GB</span>
      </a-space>
    </a-upload>

    <div v-if="uploadingZipDetails || fileUploadProgress" class="mt-3">
      <template v-if="fileUploadProgress <= 0">
        <a-typography-text strong v-if="uploadingZipDetails < 100">
          Uploading zip file in progress...
        </a-typography-text>
        <a-typography-text strong v-else>
          Zip file uploaded. Please wait for extraction...
        </a-typography-text>
      </template>
      <a-typography-text strong v-else>
        <template v-if="fileUploadStatus === activeStatus">
          Unzipping files in progress...
        </template>
        <template v-else> Files unzip progress completed. </template>
      </a-typography-text>

      <a-progress
        v-if="uploadingZipDetails < 100"
        :percent="uploadingZipDetails || fileUploadProgress"
        :status="fileUploadStatus"
      />
      <div v-if="failedFiles.length" class="mt-3">
        <a-typography-text strong>
          Following Files failed to upload:
        </a-typography-text>
        <a-list
          size="small"
          bordered
          :data-source="failedFiles"
          style="max-width: 100%; height: 15vh; overflow-y: auto"
          ><template #renderItem="{ item }">
            <a-list-item style="color: red">
              {{ item }}
            </a-list-item>
          </template></a-list
        >
      </div>
    </div>
  </a-modal>
</template>
<script>
import {
  ReloadOutlined,
  ArrowLeftOutlined,
  BarChartOutlined,
  AppstoreOutlined,
  ExclamationCircleOutlined,
  UploadOutlined,
  FolderViewOutlined,
  SearchOutlined,
  DeleteOutlined,
} from '@ant-design/icons-vue';
import { mapGetters, mapActions } from 'vuex';
import DrawObjects from './DrawObjects.vue';
import httpClient from '../../../../../service/httpClient';
import PreviewImageCard from './PreviewImageCard.vue';
import { parseStringCoordsToObj } from '../../LabelData/ObjectAnnotation/helpers';
import LRTService from 'src/services/longRunningTask';
import SQSServices from 'src/services/sqs';
import TaskObjectService from 'src/services/taskObjectsMarking';
import TaskService from 'src/services/tasks';
import { roles } from 'src/config/roles-config';
import { debounce } from 'lodash';
import axios from 'axios';
import UploadFileHelper from 'src/components/shared/Helpers/uploadFile';

export default {
  name: 'AnnotationList',
  components: {
    ReloadOutlined,
    AppstoreOutlined,
    ArrowLeftOutlined,
    BarChartOutlined,
    UploadOutlined,
    FolderViewOutlined,
    DeleteOutlined,
    SearchOutlined,
    DrawObjects,
    ExclamationCircleOutlined,
    PreviewImageCard,
  },
  inject: ['toast'],

  data() {
    return {
      isUploadingFile: false,
      fileUpload: false,
      fileList: [],
      annotatedObjectList: [],
      visible: false,
      openObjectAnnotateDialog: false,
      requestedImagePath: '',
      requestedImageId: '',
      requestImageAnnotationPath: '',
      requestImageAnnotationName: '',
      user: localStorage.getItem('id'),
      currentPageForImage: 1,
      pageSizeForImage: 30,
      currentImageIndex: -1,
      selectedImage: null,
      presignedUrlList: {},
      imageSpinner: false,
      loading: true,
      startIndexCache: 0,
      endIndexCache: 30,
      polling: null,
      isPolling: false,
      userRole: false,
      timeout: null,
      imageSpinner: false,
      startIndexCache: 0,
      endIndexCache: 30,
      listView: true,
      isBulkDelete: false,
      selectImage: {},
      selectAllImages: false,
      searchImage: '',
      searchDebounce: '',
      imageFileList: [],
      totalImagesCount: 0,
      longRunningTaskId: null,
      fileUploadProgress: 0,
      fileUploadStatus: 'active',
      activeStatus: 'active',
      failedFiles: [],
      assignImageUrl: false,
      markStatic: false,
      markStaticImageId: null,
      staticImage: {},
      uploadingZipDetails: 0,
    };
  },

  watch: {
    visible: async function (value) {
      if (!value) return;
      this.annotatedObjectList = [];
      await this.getAnnotatedObjectCount();
    },
    async selectedImage(newValue) {
      if (newValue == null) {
        this.presignedUrlList = {};
        await this.handleGetImagesForAnnotation();
      }
    },

    imageFileList(value) {
      if (value.length === 0) {
        this.selectAllImages = false;
        this.selectImage = {};
      } else {
        let set = new Set();
        for (let image of value) {
          set.add(`${image.id}`);
          this.staticImage[image.id] =
            image.id == this.taskStaticAnnotationImage;
        }
        Object.keys(this.selectImage).forEach((key) => {
          if (!set.has(`${key}`)) this.selectImage[key] = false;
        });
        if (this.selectAllImages) {
          for (let image of value) {
            this.selectImage[`${image.id}`] = true;
          }
        }
      }
    },

    selectAllImages(value) {
      if (!value) this.selectImage = {};
    },
  },

  computed: {
    ...mapGetters([
      'organization',
      'taskName',
      'selectedTask',
      'taskObjects',
      'taskRegionMarkingImgUrl',
      'loadingImages',
      'imagesForAnnotation',
      'totalImagesForAnnotation',
      'taskStaticAnnotationImage',
      'taskStaticObjectsList',
    ]),

    displayStaticSwitch() {
      let count = 0;
      let foundObj = null;
      for (const [key, value] of Object.entries(this.selectImage)) {
        if (value) {
          count += 1;
          foundObj = key;
        }
      }

      if (count !== 1) return false;
      this.markStatic = false;
      this.markStaticImageId = foundObj;
      if (`${foundObj}` === `${this.taskStaticAnnotationImage}`) {
        this.markStatic = true;
      }
      return true;
    },

    shouldDeleteDisabled() {
      for (const [key, value] of Object.entries(this.selectImage)) {
        if (value) {
          return false;
        }
      }
      this.selectAllImages = false;
      return true;
    },

    getSelectedImagesCount() {
      let count = 0;
      for (const [key, value] of Object.entries(this.selectImage)) {
        if (value) {
          count += 1;
        }
      }
      return count;
    },

    cardBodyStyle() {
      return !this.selectedImage
        ? {
            flexGrow: '1',
            height: '1px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }
        : { height: '100%' };
    },

    mainComponentStyle() {
      let style = {
        margin: 'auto',
        width: '85%',
      };

      return !this.selectedImage ? style : {};
    },
  },

  async mounted() {
    this.getActiveUserRole();
    this.imageSpinner = true;
    await this.handleGetImagesForAnnotation();
    this.imageSpinner = false;
    await this.getAnnotatedObjectCount();
  },

  async unmounted() {
    await this.resetAnnotationImagesList();
    // cleanup
    this.annotatedObjectList = [];
    this.requestImageAnnotationPath = '';
    this.requestImageAnnotationName = '';
  },

  methods: {
    ...mapActions([
      'getTaskObjects',
      'loadImagesForAnnotation',
      'setAnnotationImagesList',
      'setAnnotationImagesListCount',
      'setListLastImageObject',
      'resetAnnotationImagesList',
      'setTaskStaticImage',
      'updateTaskObject',
    ]),

    async removeStaticImageCoordinates() {
      const objectList = this.getObjectListFromState();
      if (!objectList?.length) return;
      const promises = objectList.map((item) => {
        let payload = {
          objectId: item.id,
          objectData: {
            ...item,
            cordinates_of_static_object: null,
          },
        };
        return this.updateTaskObject(payload);
      });

      await Promise.all(promises);
    },

    getObjectListFromState() {
      const new_regions = this.getNewlyAddedObjs().map((o) => {
        return {
          ...o,
          cordinates_of_static_object: parseStringCoordsToObj(
            o.cordinates_of_static_object
          ),
          actual_name: o.name,
        };
      });

      let k = this.taskStaticObjectsList
        .filter((t) => t.step_id === null)
        .map((o) => {
          return {
            ...o,
            cordinates_of_static_object: parseStringCoordsToObj(
              o.cordinates_of_static_object
            ),
            actual_name: o.name,
          };
        });

      k = k.concat(new_regions);
      return k;
    },

    getNewlyAddedObjs() {
      return this.taskObjects.filter((t) => {
        let obj_exists = false;
        this.taskStaticObjectsList.filter((o) => {
          if (o.name === t.name) {
            obj_exists = true;
          }
        });
        return !obj_exists && t.is_static;
      });
    },

    async handleMarkStaticChanged(checked, event) {
      const payload = {
        annotation_image_id: checked ? this.markStaticImageId : null,
      };
      let [error, data] = await TaskService.updateTask(
        this.selectedTask,
        payload
      );
      if (error) return;
      this.setTaskStaticImage(payload.annotation_image_id);
      this.updateStaticImageList();
      await this.removeStaticImageCoordinates();
    },

    updateStaticImageList() {
      const keys = Object.keys(this.staticImage);
      keys.forEach((key) => {
        if (key === this.taskStaticAnnotationImage) {
          this.staticImage[key] = true;
        } else this.staticImage[key] = false;
      });
    },

    getImagetItemIndex(item) {
      return this.imagesForAnnotation.findIndex(
        (value) => value.id === item.id
      );
    },

    async handlePageChange(page) {
      this.currentPageForImage = page;
      await this.updateImageFileList();
    },

    async handlePageSizeChange(current, pageSize) {
      this.pageSizeForImage = pageSize;
      this.currentPageForImage = current;
      await this.updateImageFileList();
    },

    debouncedSearch: debounce(async function () {
      this.searchDebounce = this.searchImage;
      this.currentPageForImage = 1;
      await this.updateImageFileList();
    }, 500),

    getMinMaxIndex(search_image_list) {
      let minIndex, maxIndex;

      // If only one object, adjust min and max accordingly
      if (search_image_list.length === 1) {
        const singleIndex = search_image_list[0].index;
        minIndex = Math.max(0, singleIndex - 15); // Ensure min is not less than 0
        maxIndex = Math.min(singleIndex + 15, this.imagesForAnnotation.length); // Ensure max is not greater than this.imagesForAnnotation length
      } else {
        // Find min and max indices from the list
        minIndex = Math.min(...search_image_list.map((img) => img.index));
        maxIndex = Math.max(...search_image_list.map((img) => img.index)) + 1;
      }

      return { minIndex, maxIndex };
    },
    async updateImageFileList() {
      let temp_image = [...this.imagesForAnnotation];
      temp_image = this.getSearchedFiles(temp_image);
      this.totalImagesCount = temp_image.length;
      this.imageFileList = temp_image;
      if (!this.totalImagesCount) return;
      try {
        if (this.searchDebounce) {
          this.assignImageUrl = true;
          const { minIndex, maxIndex } = this.getMinMaxIndex(temp_image);
          this.presignedUrlList = await this.generateBulkImageS3Url(
            minIndex,
            maxIndex,
            this.organization,
            this.selectedTask
          );
          this.startIndexCache = minIndex;
          this.endIndexCache = maxIndex;
          return;
        }

        this.startIndexCache =
          (this.currentPageForImage - 1) * this.pageSizeForImage;
        this.endIndexCache = this.startIndexCache + this.pageSizeForImage;
        if (this.endIndexCache >= temp_image.length)
          this.endIndexCache = temp_image.length;
        if (
          !this.searchDebounce &&
          (!this.presignedUrlList?.image?.[this.startIndexCache] ||
            !this.presignedUrlList?.image?.[this.endIndexCache - 1])
        ) {
          this.assignImageUrl = true;
          this.presignedUrlList = await this.generateBulkImageS3Url(
            this.startIndexCache,
            this.endIndexCache,
            this.organization,
            this.selectedTask
          );
        }
      } finally {
        if (!this.assignImageUrl) return;

        const indexMap = new Map(
          this.imageFileList.map((item, index) => [+item.index, index])
        );

        Object.entries(this.presignedUrlList?.image).forEach(([key, value]) => {
          const elementExist = indexMap.has(+key);
          if (elementExist) {
            this.imageFileList[indexMap.get(+key)].url = value;
          }
        });

        let tempAnnotaionImageList = [...this.imagesForAnnotation];
        indexMap.forEach((value, key) => {
          tempAnnotaionImageList[key].url = this.imageFileList[value].url;
        });

        this.setAnnotationImagesList(tempAnnotaionImageList);
        this.assignImageUrl = false;
      }
    },

    getSearchedFiles(imageList) {
      const searchLowerCase = this.searchDebounce.toLowerCase().trim();
      const imageListWithIndex = imageList.map((image, index) => ({
        ...image,
        index: index, // Add the index attribute here
      }));

      return imageListWithIndex.filter((image) =>
        this.getFileName(image.path_to_image)
          .toLowerCase()
          .includes(searchLowerCase)
      );
    },

    handleSelectAllFiles(value) {
      for (let file of this.imageFileList) {
        this.selectImage[file.id] = this.selectAllImages;
      }
    },

    async handleDelete() {
      let keys = Object.keys(this.selectImage).filter(
        (key) => this.selectImage[key] === true
      );
      if (!keys.length) return;
      this.isBulkDelete = true;
      try {
        const [error, data] = await TaskObjectService.bulkDeleteAnnotations(
          { ids: keys },
          true
        );
        if (error) {
          await this.handleGetImagesForAnnotation();
          return this.toast.error('Failed to delete files.');
        }
        let msgSuccess = true;
        if (data?.failed_files?.length) {
          msgSuccess = false;
          for (let file of data.failed_files) {
            keys = keys.filter((item) => item != file);
          }
        }
        keys.forEach((key) => this.updateImageList(key));
        await this.updateTaskStaticImageAndCoordinates(keys);
        this.presignedUrlList = {};
        this.currentPageForImage = 1;
        await this.updateImageFileList();
        return msgSuccess
          ? this.toast.success(data.message)
          : this.toast.error(data.message);
      } catch (err) {
        this.toast.error('Unable to delete some files.');
      } finally {
        this.isBulkDelete = false;
      }
    },

    async updateTaskStaticImageAndCoordinates(keys) {
      const staticImage = keys.filter(
        (item) => item == this.taskStaticAnnotationImage
      );

      if (staticImage.length) {
        await this.resetStaticImageAndCoordinates();
      }
    },

    async resetStaticImageAndCoordinates() {
      if (!this.taskStaticAnnotationImage) return;
      this.staticImage[this.taskStaticAnnotationImage] = false;
      this.setTaskStaticImage(null);
      await this.removeStaticImageCoordinates();
    },

    resetSelection() {
      this.selectAllImages = false;
      this.selectImage = {};
    },

    addItemInImageList(file) {
      let tempAnnotation = this.imagesForAnnotation;
      tempAnnotation.push(file);
      this.setAnnotationImagesList(tempAnnotation);
      this.setAnnotationImagesListCount(tempAnnotation.length);
    },

    updateSelectAll(obj) {
      this.selectImage[obj.id] = obj.selected;
    },

    handleChangeView() {
      this.listView = !this.listView;
    },

    getActiveUserRole() {
      const user = localStorage.getItem('role');
      if (user === roles.support_user) this.userRole = true;
      else this.userRole = false;
    },

    handleUploadFile(info) {
      const isZip = info.file.name.endsWith('.zip');
      const maxSizeInBytes = 1 * 1024 * 1024 * 1024; // 1 GB
      if (!isZip) {
        this.toast.error('Please upload .zip files only!');
        this.fileList = [];
        return;
      }
      if (info.file.size > maxSizeInBytes) {
        this.toast.error('File size must be less than 1 GB!');
        this.fileList = [];
        return;
      }
    },

    async reloadImagesAfterUpload() {
      await this.reloadImages();
      this.currentPageForImage = 1;
    },

    async uploadFileOnS3(file) {
      const s3Key = `${this.taskName}/compressedImages/${file.name}`;
      const response = await UploadFileHelper.getProjectorFileUploadURL(s3Key);
      if (!response?.url) {
        return false;
      }
      try {
        const payload = UploadFileHelper.createAWSFormData(file, response);
        const headers = {
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
        };
        await axios.post(response.url, payload, {
          headers,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
              this.fileUploadStatus = percent < 100 ? this.activeStatus : '';
              this.uploadingZipDetails = percent;
            }
          },
        });
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async handleUploadImageFile() {
      if (this.fileUploadProgress === 100) {
        await this.handleCancel();
        return;
      }
      this.isUploadingFile = true;
      const file = this.fileList[0].originFileObj;
      const error = await this.uploadFileOnS3(file);
      if (!error) {
        this.isUploadingFile = false;
        this.toast.error('Failed to upload file!');
        return;
      }
      this.longRunningTaskId = await this.createLongRunningTaskId();
      if (!this.longRunningTaskId) {
        this.handleCancel();
        this.toast.error('Failed to unzip files!');
        return;
      }
      const response = await this.sendMessageToQueue(file.name);
      if (!response) {
        this.handleCancel();
        this.toast.error('Failed to unzip files!');
        return;
      }
      this.toast.info('Please wait while we unzip images.');
      this.pollImageStatus();
    },

    async createLongRunningTaskId() {
      const payload = {
        task_id: this.selectedTask,
        name: 'decompress_annotation_images',
        organization: this.organization,
        status: 'started',
      };
      const [error, data] = await LRTService.createLongRunningTasks(
        payload,
        true
      );
      if (error) return null;
      return data.id;
    },

    async sendMessageToQueue(fileName) {
      const payload = {
        queueName: 'decompress-od-images-dev-jobs',
        sqsMessage: {
          task_id: this.selectedTask,
          task_name: this.taskName,
          organization: this.organization,
          file_name: fileName,
          long_running_task_id: this.longRunningTaskId,
        },
      };

      const [error] = await SQSServices.sendSQSMessage(payload);
      if (error) {
        console.log('error:', error);
        return false;
      }
      return true;
    },

    async handleCancelUploadModal() {
      if (this.isUploadingFile) return this.toast.info('Please wait...');
      await this.handleCancel();
    },

    async handleCancel() {
      this.fileUpload = false;
      this.isUploadingFile = false;
      this.fileList = [];
      this.failedFiles = [];
      this.uploadingZipDetails = 0;
      this.fileUploadStatus = this.activeStatus;
      if (this.fileUploadProgress === 100) await this.reloadImagesAfterUpload();
      this.fileUploadProgress = 0;
    },

    pollImageStatus() {
      this.fileUploadStatus = this.activeStatus;
      this.initializeTimeout();
      this.startPolling();
    },

    initializeTimeout() {
      this.timeout = setTimeout(async () => {
        if (this.polling === null) return;
        this.toast.error('Failed to unzip images. Timeout occured!');
        this.resetPolling();
        await this.handleCancel();
        await this.reloadImagesAfterUpload();
      }, 900000); // 15 mins
    },

    resetTimeout() {
      clearTimeout(this.timeout);
      this.timeout = null;
    },

    startPolling() {
      this.polling = setInterval(this.fetchImageStatus, 3000);
    },

    resetPolling() {
      clearInterval(this.polling);
      this.polling = null;
      this.longRunningTaskId = null;
      this.resetTimeout();
    },

    async fetchImageStatus() {
      if (this.isPolling) return;
      this.isPolling = true;
      try {
        const [error, data] = await LRTService.getLongRunningTaskById(
          this.longRunningTaskId,
          false
        );
        if (error) {
          this.toast.error('Failed to unzip images!');
          await this.handleCancel();
          this.resetPolling();
          return;
        }
        if (data?.details?.progress) {
          this.uploadingZipDetails = 0;
          this.fileUploadStatus = this.activeStatus;
          this.fileUploadProgress = Math.floor(data.details.progress);
          if (this.fileUploadProgress === 100) {
            this.fileUploadStatus = '';
            this.isUploadingFile = false;
          }
        }
        if (data?.status === 'finished') {
          this.resetPolling();
          if (data?.details?.files_not_uploaded?.length) {
            this.failedFiles = data?.details?.files_not_uploaded;
          } else {
            await this.handleCancel();
          }
        }
        if (data?.error_field) {
          this.toast.error('Failed to unzip images!');
          await this.handleCancel();
          this.resetPolling();
          return;
        }
      } finally {
        this.isPolling = false;
      }
    },

    currentImageIndexChanged(index) {
      if (index < 0 || index >= this.totalImagesForAnnotation) {
        this.loading = false;
      }
      this.selectedImage = this.imagesForAnnotation[index];
      this.openImageForAnnotation(this.imagesForAnnotation[index]);
      this.loading = false;
    },

    async getAnnotatedObjectCount() {
      await this.getTaskObjects(this.selectedTask);
      this.annotatedObjectList = [];
      this.taskObjects.forEach((obj) => {
        this.annotatedObjectList.push({
          name: obj.name,
          count: obj.number_of_examples,
          is_static: obj.is_static,
        });
      });
    },

    async handleGetImagesForAnnotation() {
      const payload = {
        task: this.selectedTask,
        page: this.currentPageForImage,
        pageSize: this.pageSizeForImage,
        ordering: 'id',
      };
      await this.loadImagesForAnnotation(payload).then(async () => {
        this.currentPageForImage = this.currentPageForImage;
        this.presignedUrlList = {};
        await this.updateImageFileList();
      });
    },

    async deleteImageAnnotation(id) {
      const res = await httpClient.delete(
        `organization/task/annotation_image/`,
        id + '/',
        false
      );
      if (res === 'error') {
        this.toast.error('error in getting images');
        return;
      }
      delete this.selectImage[id];
      if (!this.listView) return await this.handleGetImagesForAnnotation();
      this.updateImageList(id);
    },

    updateImageList(id) {
      const tempAnnotation = this.imagesForAnnotation.filter(
        (item) => item.id != id
      );
      this.setAnnotationImagesList(tempAnnotation);
      this.setAnnotationImagesListCount(tempAnnotation.length);
    },

    async generateBulkImageS3Url(startIndex, endIndex, organization, taskId) {
      const obj = {
        bucket_name: organization + '-training',
        task_id: taskId,
        start_index: startIndex,
        end_index: endIndex,
      };
      try {
        const response = await httpClient.post(
          'generic/generate_list_new_url/',
          obj,
          false,
          false,
          false
        );
        const presigned_url = response.presigned_url; // Assuming response structure

        return presigned_url;
      } catch (error) {
        console.error('Error generating presigned URL ' + error, 3000);
      }
    },

    async reloadImages() {
      this.selectImage = {};
      this.presignedUrlList = {};
      await this.handleGetImagesForAnnotation();
    },

    handleImageChange(newIndex) {
      this.currentImageIndex += newIndex;
      this.currentPageForImage =
        Math.floor(this.currentImageIndex / this.pageSizeForImage) + 1;
      this.currentImageIndexChanged(this.currentImageIndex);
      this.updateImageFileList();
    },

    async openAnnotationModal(item) {
      const index = this.getImagetItemIndex(item);
      if (index === -1)
        return this.toast.error('Failed to open Annotation tool!');
      this.selectedImage = this.imagesForAnnotation[index];
      this.currentImageIndex = index;
      this.loading = false;
      this.currentImageIndexChanged(this.currentImageIndex);
      this.searchImage = '';
      this.searchDebounce = '';
      await this.updateImageFileList();
      const presigned_keys = Object.keys(this.presignedUrlList.image);
      const presigned_url_length = presigned_keys.length;
      const last_presigned_index = presigned_keys[presigned_url_length - 1];
      const first_presigned_index = presigned_keys[presigned_url_length - 1];
      if (last_presigned_index < index || first_presigned_index > index) {
        this.startIndexCache = Math.max(0, index - 15);
        this.endIndexCache = Math.min(
          index + 15,
          this.imagesForAnnotation.length
        );
      }
    },

    getCurrentIndex(index) {
      return (this.currentPageForImage - 1) * this.pageSizeForImage + index;
    },

    setImageData(image) {
      if (!image) return;
      this.requestedImagePath = image.path_to_image;
      this.requestedImageId = image.id;
      this.requestImageAnnotationName = this.getFileName(
        image.path_to_annotations
      );
      this.requestImageAnnotationPath = image.path_to_annotations;
    },

    async deleteAnnotation(index, item) {
      await this.deleteImageAnnotation(item['id']);
    },

    openImageForAnnotation(image) {
      this.setImageData(image);
      this.openObjectAnnotateDialog = true;
      this.startTime = new Date().toISOString();
    },

    async updateAnnotationPath(updatedAnnotationPath) {
      const temp_imgs = [...this.imagesForAnnotation];
      temp_imgs[this.currentImageIndex].path_to_annotations =
        updatedAnnotationPath;
      this.requestImageAnnotationPath = updatedAnnotationPath;

      await this.setAnnotationImagesList(temp_imgs);
    },

    getFileName(name) {
      if (name) return name.split('/').at(-1);
    },
  },
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 75vh; /* Ensures spinner is centered vertically within the viewport */
}
</style>

<style scoped>
#components-grid-demo-playground pre {
  background: #f9f9f9;
  border-radius: 6px;
  font-size: 13px;
  padding: 8px 16px;
}
</style>
<style>
#object-detection-pagination
  > li:last-child
  .ant-pagination-options-size-changer.ant-select {
  width: 90px !important;
}
.grid-icon-btn {
  border-color: #d9d9d9;
}
.grid-icon-btn svg {
  width: 14px;
  height: 14px;
}
#label-objects-list .ant-list-item {
  display: flex;
  justify-content: center;
}

#mark-objects-card > .ant-card-body {
  padding: 0px !important;
}

.selected-item {
  background-color: lightgray;
}

.file-item:hover {
  background-color: #f5f5f5; /* Very light gray for hover effect */
}

.list-pagination-display .ant-list-pagination {
  display: none;
}
</style>
